import { AuctionDTO, AuctionLine } from 'models/erp'
import { FutureAuctionVehicleViewModel } from 'models/viewmodels'
import { addPercentCkCp } from '../addPercentCkCp'

const extractFutureAuctionVehiclesViewModel = (auction: AuctionDTO) => {
  const auctionViewModelsArray = auction.auctionLines.map((line: AuctionLine) => {
    const viewModel: FutureAuctionVehicleViewModel = {
      no: line.no,
      passingOrderNo: line.passingOrderNo,
      licensePlate: line.vehiclePartial?.licensePlate,
      color: line.vehiclePartial?.color,
      type: line.vehiclePartial?.type,
      dateFirstReg: line.vehiclePartial?.dateFirstReg,
      id: line.vehicleDetail?.id,
      version: line.vehicleDetail?.version,
      estimationVPAuto: line.pricing?.estimationVPAuto,
      coteArgusPT: line.pricing?.coteArgusPT,
      coteArgusKm: line.pricing?.coteArgusKm,
      reserveHF: line.pricing?.reserveHF,
      percentPT: null, // TODO: addPercentCkCp(line: AuctionLine || Vehicle, ratingType: 'ck'|'cp' ) Assuming this is calculated later
      percentCK: null, // Assuming this is calculated later
      mileage: line.information?.mileage,
      observationsVendeur: line.information?.observationsVendeur,
      derniereOffreVentePhysique: line.history?.derniereOffreVentePhysique,
      derniereOffreVenteElectro: line.history?.derniereOffreVenteElectro,
      tvaRecuperable: line.pricing?.tvaRecuperable,
      nombrePassageVente: line.history?.nombrePassageVente,
    }
    return addPercentCkCp(viewModel)
  })
  return auctionViewModelsArray
}

export { extractFutureAuctionVehiclesViewModel }
