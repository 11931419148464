/* eslint-disable max-len */

import {
  CalculationMethodsCode,
  EntityTypeCode,
  RolesPossibleValues,
  SegmentationsCode,
} from 'utils/constants'

import { AddressInfo, AllAddresses, defaultAddress, defaultAllAddresses } from './address'
import { ResponseUserAD } from '../adUser'

type EntityWithoutAddress = {
  id: string
  apeCode: string
  commercialName: string
  commercialNameInt: string
  salesForceId: string
  name: string
  siret: string
  siren: string
  status: boolean
  roles: RolesPossibleValues
  vat: string
  cardCodeSapBuyer: string
  cardCodeSapSeller: string
  paymentType: string
  origin: string
  kyc: boolean
  isVatRecoverable: boolean
  isVatDepositExempt: boolean
  isCarProfessional: boolean
  representativeOf: Array<string>
  principalContact: string
  language: string
  calculationMethod: CalculationMethodsCode
  segmentation: SegmentationsCode
  outstandingAmount: boolean
  vpaRepresentative: Array<ResponseUserAD>
  parentId: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  entityType: EntityTypeCode
  kbisFileStorageId: string
  documentFileStorageId: string
  createdBy: string
  createdOn: string
  children: Array<string> | null
  childrenCount: number
}

type Entity = EntityWithoutAddress & AllAddresses

type EntityFlattened = EntityWithoutAddress & AddressInfo

type EntityWithoutAddressBeforeCreate = Omit<EntityWithoutAddress, 'id'>
type EntityBeforeCreate = Omit<Entity, 'id'>
type EntityBeforeCreateFlattened = Omit<EntityFlattened, 'id'>

const defaultEntityWithoutAddressBeforeCreate: EntityWithoutAddressBeforeCreate = {
  apeCode: '',
  commercialName: '',
  commercialNameInt: '',
  salesForceId: '',
  name: '',
  siret: '',
  siren: '',
  status: false,
  roles: 0,
  vat: '',
  cardCodeSapBuyer: '',
  cardCodeSapSeller: '',
  paymentType: '',
  origin: '',
  kyc: false,
  isVatRecoverable: false,
  isVatDepositExempt: false,
  isCarProfessional: true,
  representativeOf: [],
  principalContact: '',
  language: '',
  calculationMethod: 0,
  segmentation: 0,
  outstandingAmount: false,
  vpaRepresentative: [],
  parentId: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  entityType: 0,
  kbisFileStorageId: '',
  documentFileStorageId: '',
  createdBy: '',
  createdOn: '',
  children: null,
  childrenCount: 0,
}

const defaultEntityBeforeCreate: EntityBeforeCreate = {
  ...defaultEntityWithoutAddressBeforeCreate,
  ...defaultAllAddresses,
}

const defaultEntityBeforeCreateFlattened: EntityBeforeCreateFlattened = {
  ...defaultEntityWithoutAddressBeforeCreate,
  ...defaultAddress,
}

const defaultEntity: Entity = { id: '', ...defaultEntityBeforeCreate }
const defaultEntityFlattened: EntityFlattened = { id: '', ...defaultEntityBeforeCreateFlattened }

export type {
  EntityWithoutAddress,
  Entity,
  EntityFlattened,
  EntityBeforeCreate,
  EntityBeforeCreateFlattened,
}

export {
  defaultEntity,
  defaultEntityFlattened,
  defaultEntityBeforeCreate,
  defaultEntityBeforeCreateFlattened,
}
