import { DataTable } from 'components/dataTable'
import { useAlert } from 'contexts'
import { VehicleUniversalData } from 'models/universalData'
import React from 'react'
import { defaultUniversalVehicle } from 'utils/helpers/extractViewModels'
import { useCheckData, useLoading } from 'utils/hooks'
import { useGetStockFromEntity } from 'utils/hooks/queries/useGetStockFromEntity'
import useVehicleModal from 'utils/hooks/useVehicleModal'
import { stockPageTableLabels } from 'utils/labels/entityStockTableLabels'

const StockPage = () => {
  const { data: stockData, isLoading, isError } = useGetStockFromEntity()
  const { displayAlert } = useAlert()
  const { Loading } = useLoading()
  const { setOpenModal, VehicleModal } = useVehicleModal()
  const { dataAfterCheck, hasData, isDataEmpty } = useCheckData<VehicleUniversalData>(
    stockData,
    defaultUniversalVehicle,
  )

  const [vehicleModalActiveId, setVehicleModalActiveId] = React.useState<string>('')

  const stock: Array<VehicleUniversalData> = dataAfterCheck
  const displayedTitle = `Véhicules en stock : ${hasData ? stock.length : 0}`

  React.useEffect(() => {
    if (isError) {
      displayAlert('Erreur lors du chargement du stock', 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  if (isLoading) return <Loading />
  if (isDataEmpty) return <p>Aucun véhicule en stock.</p>

  return (
    <>
      <DataTable
        columnsTitles={stockPageTableLabels}
        data={stock}
        tableTitle={displayedTitle}
        options={{
          onRowClick: (line) => {
            const typedLine = line as unknown as VehicleUniversalData
            setVehicleModalActiveId(typedLine.no)
            setOpenModal(true)
          },
        }}
      />
      <VehicleModal
        id={vehicleModalActiveId}
        onClose={() => setVehicleModalActiveId('')}
      />
    </>
  )
}

export default StockPage
