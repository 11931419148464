import { ExportableDataTables, DataTableProps, DateISO8601 } from 'models'
import { formatDate } from './formatDate'

const formatTableDataToCSV = <T extends ExportableDataTables>({
  columnsTitles,
  data,
}: Omit<DataTableProps<T>, 'tableTitle' | 'options'>) => {
  const columnsTitlesKeys = Object.keys(columnsTitles) as unknown as Array<keyof T>
  const resultArray = []
  const csvHeaders = `${Object.values(columnsTitles).join(';')}`
  resultArray.push(csvHeaders)
  data.map((line) => {
    const array: string[] = []
    columnsTitlesKeys.map((key) => {
      if (line[key] === null) {
        array.push('')
        return undefined
      }
      // if (key.toString().startsWith('date') && key !== 'dateVenteFR' && key !== 'dateCreation') {
      //   console.log('formating for ', key)
      //   console.log('entry', line[key])
      //   // array.push(formatDate(line[key] as DateISO8601))
      //   array.push('yep')
      //   return undefined
      // }
      if (typeof line[key] === 'number') {
        const num = line[key] as number
        array.push(num.toString() || '')
        return undefined
      }

      array.push(line[key] as string)
      return undefined
    })
    resultArray.push(`${array.join(';')}`)
    return undefined
  })
  return `\uFEFF ${resultArray.join('\n')}`
}

export { formatTableDataToCSV }
