import React from 'react'
import { routes } from 'Routes'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { AppProvider, useAuthenticatedUser } from 'contexts'
import { LOCAL_STORAGE_KEYS } from 'utils/constants'
import { useLoading } from 'utils/hooks'

const router = createBrowserRouter(routes)

const App: React.FC = () => {
  const { isLoading } = useAuthenticatedUser()
  const { Loading } = useLoading()

  React.useEffect(() => {
    return () => localStorage.removeItem(LOCAL_STORAGE_KEYS.AFTER_SIGN_IN_URL)
  }, [])

  return isLoading ? (
    <Loading />
  ) : (
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  )
}

export default App
