
import { PrivateRouteProvider } from 'contexts'

import React from 'react'
import { Outlet } from 'react-router-dom'


const PrivateRoute = () => {
  return (
    <PrivateRouteProvider>
      <Outlet />
    </PrivateRouteProvider>
  )

}

export default PrivateRoute
