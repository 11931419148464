import { defaultVehicle } from 'models/erp'
import { VehicleUniversalData } from 'models/universalData'
import { extractUniversalVehicles } from 'utils/helpers/extractViewModels'

type StockPageTableLabels = Partial<Record<keyof VehicleUniversalData, string>>

const stockPageTableLabels: StockPageTableLabels = {
  // assign "undefined" to property if you don't want it to appear in data table
  nbJ: 'NJ',
  no: 'Réf',
  licensePlate: 'Immat',
  bodyType: 'Genre',
  make: 'Marque',
  model: 'Modèle',
  dateProduction: 'Date création',
  dateDelivery: 'Date livraison',
  auctionStartDateTime: 'Date vente',
  locationName: 'Parc Stockage',
  auctionStatus: 'Statut',
  administrativeStatus: 'Sous statut',
}

export type { StockPageTableLabels }
export { stockPageTableLabels }
