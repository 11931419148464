import React from 'react'
import { DataTable } from 'components/dataTable'
import { useNavigate } from 'react-router-dom'
import { useAlert, useAuction } from 'contexts'
import { useCheckData, useLoading } from 'utils/hooks'

import { auctionLightTableLabels } from 'utils/labels/auctionLightTableLabels'
import { useGetAuctionsLight } from 'utils/hooks/queries'
import { AuctionLightOverload, defaultAuctionLightOverLoad } from 'models/universalData'

const SalesResultPage = () => {
  const navigate = useNavigate()
  const { data: salesResultData, isLoading, isError } = useGetAuctionsLight('past')
  const { displayAlert } = useAlert()
  const { Loading } = useLoading()
  const { setAuction } = useAuction()
  const { dataAfterCheck, isDataEmpty } = useCheckData<AuctionLightOverload>(
    salesResultData,
    defaultAuctionLightOverLoad,
  )

  const salesResult: Array<AuctionLightOverload> = dataAfterCheck

  React.useEffect(() => {
    if (isError) {
      displayAlert('Erreur lors du chargement des résultats des ventes!', 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  if (isLoading) return <Loading />
  if (isDataEmpty) return <p>Aucune vente à afficher.</p>

  return (
    <DataTable
      columnsTitles={auctionLightTableLabels}
      tableTitle="Résultats de vente"
      data={salesResult}
      options={{
        onRowClick: (sale) => {
          const typedSale = sale as unknown as AuctionLightOverload
          if (typedSale && typedSale.no) {
            setAuction(typedSale)
            navigate(typedSale.no)
          }
        },
      }}
    />
  )
}

export default SalesResultPage
