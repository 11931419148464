import { AuctionDTO } from 'models/erp'
import { formatDateIsoToFr } from './format'

// TODO: repasser à un traitement différent par page

const generateAuctionsTableTitle = (auction: AuctionDTO) => {
  const isAuctionOnline = auction.auctionType !== 0
  //  const date = formatDateIsoToFr(auction.auctionStartDateTime)
  const date = auction.auctionStartDateTime
  if (isAuctionOnline) {
    return `Vente en ligne du ${date}`
  }
  return ` ${auction.description}, le ${date}`
}

export { generateAuctionsTableTitle }
