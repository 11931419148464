/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { ComboBox } from 'components/common'
import { useEntities, useWaitingFilesCount } from 'contexts'
import { Entity } from 'models/erp'
import { AutocompleteChangeReason } from '@mui/material'
import { theme } from 'utils/theme'
import { entityDisplayName, isEntityDefault } from 'utils/helpers'

const NavBarSellerFamily = () => {
  const {
    allEntities,
    selectedParent,
    removeSelectedChild,
    removeSelectedParent,
    removeSelectedFilteringEntity,
    setSelectedParent,
  } = useEntities()

  const { setWaitingFilesCount } = useWaitingFilesCount()

  const allParents = [...allEntities] || []

  const [parentInput, setParentInput] = React.useState<Entity | null>(null)

  React.useEffect(() => {
    if (!isEntityDefault(selectedParent)) {
      setParentInput(selectedParent)
    }
  }, [parentInput, selectedParent])

  const handleParentChange = (
    _event: React.SyntheticEvent<Element, Event>,
    option: Entity | null,
    reason: AutocompleteChangeReason,
  ) => {
    removeSelectedParent()
    removeSelectedFilteringEntity()
    removeSelectedChild()
    setWaitingFilesCount(0)
    if (reason === 'clear') {
      return
    }
    setSelectedParent(option as Entity)
  }

  return allParents.length > 0 ? (
    <ComboBox
      options={allParents}
      getOptionLabel={(option: Entity) => entityDisplayName(option, true)}
      getOptionKey={(option: Entity) => option.id}
      selectedOption={parentInput}
      onSelection={handleParentChange}
      boxStyle={
        selectedParent
          ? undefined
          : {
              border: `3px solid ${theme.palette.primary.main}`,
            }
      }
    />
  ) : null
}

export default NavBarSellerFamily
