import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { PAGES, Page } from 'utils/constants'
import { theme } from 'utils/theme'
import { NotificationBadge } from 'components/common'
import { useWaitingFilesCount } from 'contexts'
import { useGetWaitingFilesFromEntity } from 'utils/hooks/queries/useGetWaitingFilesFromEntity'

type NavBarListItemProps = {
  page: Page
  open: boolean
}

const NavBarListItem = ({ page, open }: NavBarListItemProps) => {
  const { data: waitingFilesData } = useGetWaitingFilesFromEntity()
  const { waitingFilesCount, setWaitingFilesCount } = useWaitingFilesCount()

  React.useEffect(() => {
    if (waitingFilesData) {
      setWaitingFilesCount(waitingFilesData.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingFilesData])

  return (
    <NavLink
      to={page.link}
      style={({ isActive }) => {
        return {
          fontWeight: isActive ? 'bold' : '',
          backgroundColor: isActive ? theme.palette.secondary.light : '',
          color: isActive ? theme.palette.primary.dark : theme.palette.secondary.light,
        }
      }}
    >
      <ListItem
        key={page.text}
        disablePadding
        sx={{
          'display': 'block',
          'color': 'inherit',
          'backgroundColor': 'inherit',
          '&:hover': {
            fontWeight: 700,
          },
        }}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
            py: 2,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
              color: 'inherit',
            }}
          >
            {page.icon}
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 'inherit',
                }}
              >
                {page.text}
                {page === PAGES.waitingFiles ? (
                  <NotificationBadge number={waitingFilesCount} />
                ) : null}
              </Typography>
            }
            sx={{
              opacity: open ? 1 : 0,
            }}
          />
        </ListItemButton>
      </ListItem>
    </NavLink>
  )
}

export default NavBarListItem
