import { AuctionLightDTO } from 'models/erp'
import { AuctionLightOverload } from 'models/universalData'
import { isDateFuture } from './isDateFuture'

const stringify = (num: number): string => num.toString() || '?'
const getPlural = (num: number): string => (num > 1 ? 's' : '')

const generateAuctionLib = (auctionLight: AuctionLightDTO): AuctionLightOverload => {
  const isAuctionInvalid =
    !auctionLight.auctionDate ||
    !auctionLight.numberOfLotInAuction ||
    !auctionLight.numberOfLotWithoutReserve
  if (!isAuctionInvalid) {
    return { ...auctionLight, lib: '-' }
  }
  const isFuture = isDateFuture(auctionLight.auctionDate as string)
  const isPast = !isDateFuture(auctionLight.auctionDate as string)

  const totalVh = stringify(auctionLight.numberOfLotInAuction)
  const pluralVh = getPlural(auctionLight.numberOfLotInAuction)
  const totalReserve = stringify(auctionLight.numberOfLotWithoutReserve)
  const pluralReserve = getPlural(auctionLight.numberOfLotWithoutReserve)

  if (isFuture) {
    const newLib = `${totalVh} véhicule${pluralVh}, ${totalReserve} réserve${pluralReserve} à indiquer`
    return { ...auctionLight, lib: newLib }
  }
  if (isPast) {
    const newLib = `${totalVh} véhicule${pluralVh}`
    return { ...auctionLight, lib: newLib }
  }

  return { ...auctionLight, lib: '-' }
}

export { generateAuctionLib }
