import React from 'react'
import { useCheckData, useLoading } from 'utils/hooks'
import { useAlert } from 'contexts'
import { DataTable } from 'components/dataTable'
import { useNavigate } from 'react-router-dom'

import { useAuction } from 'contexts/AuctionContext'

import { useGetAuctionsLight } from 'utils/hooks/queries'
import { auctionLightTableLabels } from 'utils/labels'
import { AuctionLightOverload, defaultAuctionLightOverLoad } from 'models/universalData'

const FutureSalesTable = ({
  title,
  auctionsData,
}: {
  title: string
  auctionsData: Array<AuctionLightOverload>
}) => {
  const navigate = useNavigate()
  const { setAuction } = useAuction()

  return (
    <DataTable
      tableTitle={title}
      columnsTitles={auctionLightTableLabels}
      data={auctionsData}
      options={{
        onRowClick: (auction) => {
          const typedSale = auction as unknown as AuctionLightOverload
          if (typedSale && typedSale.no) {
            setAuction(typedSale)
            navigate(typedSale.no)
          }
        },
      }}
    />
  )
}

const FutureSalesPage = () => {
  const { data: auctions, isLoading, isError } = useGetAuctionsLight('future')

  const { displayAlert } = useAlert()
  const { Loading } = useLoading()
  const { dataAfterCheck, isDataEmpty } = useCheckData<AuctionLightOverload>(
    auctions as unknown as AuctionLightOverload[],
    defaultAuctionLightOverLoad,
  )
  const futureAuctions: Array<AuctionLightOverload> = dataAfterCheck

  React.useEffect(() => {
    if (isError) {
      displayAlert('Erreur lors du chargement des ventes futures', 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  if (isLoading) return <Loading />
  if (isDataEmpty) return <p>Aucune vente à afficher</p>

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const physicalSales = futureAuctions.filter((auction: AuctionLightOverload) => {
    return auction.isOnlineAuction === false
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const otherSales = futureAuctions.filter((auction: AuctionLightOverload) => {
    return auction.isOnlineAuction === true
  })

  return (
    <>
      {physicalSales.length > 0 && (
        <FutureSalesTable
          title="Ventes en salle"
          auctionsData={physicalSales}
        />
      )}
      {otherSales.length > 0 && (
        <FutureSalesTable
          title="Ventes en ligne"
          auctionsData={otherSales}
        />
      )}
    </>
  )
}

export default FutureSalesPage
