import React from 'react'
import { Autocomplete, AutocompleteChangeReason, SxProps, TextField, Theme } from '@mui/material'
import { entityDisplayName } from 'utils/helpers'
import { Entity } from 'models/erp'

type ComboBoxProps<T> = {
  options: Array<T>
  getOptionLabel: (option: T) => string
  getOptionKey?: (option: T) => string
  selectedOption: T | null
  onSelection: (
    event: React.SyntheticEvent,
    value: T | null,
    reason: AutocompleteChangeReason,
  ) => void
  label?: string
  boxStyle?: SxProps<Theme>
}

const ComboBox = <T,>({
  options,
  getOptionLabel,
  getOptionKey,
  selectedOption,
  onSelection,
  label,
  boxStyle,
}: ComboBoxProps<T>) => {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionKey={getOptionKey}
      onChange={onSelection}
      value={selectedOption || null}
      isOptionEqualToValue={(option, value) =>
        entityDisplayName(option as Entity) === entityDisplayName(value as Entity)
      }
      sx={{
        width: 250,
        ...(boxStyle ? { '& .MuiOutlinedInput-notchedOutline': boxStyle } : {}),
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...(label ? { label } : {})}
          sx={{ marginLeft: '5px', padding: 0 }}
          size="small"
        />
      )}
    />
  )
}

export default ComboBox
