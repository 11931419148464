import { UseQueryResult, useQueries } from '@tanstack/react-query'
import { useAlert, useEntities, useTranslator } from 'contexts'
import { VehicleSheetDTO } from 'models'
import { defaultVehicle, Entity, Vehicle } from 'models/erp'
import { VehicleUniversalData } from 'models/universalData'
import React from 'react'
import { currentQueryService } from 'utils/api/queries'
import { isEntityDefault } from 'utils/helpers'
import { defaultUniversalVehicle, extractUniversalVehicles } from 'utils/helpers/extractViewModels'

const useGetVehicles = (key: 'no' | 'licensePlate' | 'vin', value: string) => {
  const { translateStatusVehicleArray } = useTranslator()
  const { displayAlert } = useAlert()

  const { filteringEntity, childrenEntities, selectedChild } = useEntities()
  const allFilteringEntities = isEntityDefault(selectedChild)
    ? [filteringEntity, ...childrenEntities]
    : [selectedChild]

  const results = useQueries({
    queries: allFilteringEntities.map((entity) => ({
      queryKey: ['getVehicles', key, value, entity],
      queryFn: () => {
        if (isEntityDefault(entity)) return Promise.resolve()
        return currentQueryService.getVehicles(entity, key, value)
      },
      enabled: !!key && !!value && !!entity,
      staleTime: 0,
      retry: 1,
    })),
  })

  const isGetVehicleLoading = results.some((result) => result.isLoading)
  const isGetVehicleError = results.some((result) => result.isError)

  React.useEffect(() => {
    if (isGetVehicleError) {
      displayAlert('Error while fetching vehicles', 'error')
    }
  }, [isGetVehicleError])

  const vehiclesData = isGetVehicleError
    ? []
    : results
        .filter((result) => result.data !== undefined)
        .reduce((accumulator: Vehicle[], result) => {
          return [...accumulator, ...(result.data || [])]
        }, [])

  const vehiclesUniversalData = extractUniversalVehicles(translateStatusVehicleArray(vehiclesData))

  return {
    isGetVehicleError,
    isGetVehicleLoading,
    // errorGetVehicle,
    // refetchVehicles,
    vehiclesUniversalData,
  }
}

export { useGetVehicles }
