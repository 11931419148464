import { VehicleUniversalData } from 'models/universalData'
import React from 'react'
import { defaultUniversalVehicle } from 'utils/helpers/extractViewModels'

const VehicleContext = React.createContext<VehicleUniversalData>(defaultUniversalVehicle)

const useVehicle = () => {
  const vehicleContext = React.useContext(VehicleContext)
  return vehicleContext
}
export { VehicleContext, useVehicle }
