import { DataTable } from 'components/dataTable'
import { useAlert, useTranslator } from 'contexts'
import { VehicleUniversalData } from 'models/universalData'
import React from 'react'
import { defaultUniversalVehicle } from 'utils/helpers/extractViewModels'
import { useCheckData, useLoading } from 'utils/hooks'
import { useGetWaitingFilesFromEntity } from 'utils/hooks/queries/useGetWaitingFilesFromEntity'
import useVehicleModal from 'utils/hooks/useVehicleModal'
import { waitingFilesPageTableLabels } from 'utils/labels'

const WaitingFilesPage = () => {
  const { data: waitingFilesData, isLoading, isError } = useGetWaitingFilesFromEntity()
  const { displayAlert } = useAlert()
  const { Loading } = useLoading()
  const { setOpenModal, VehicleModal } = useVehicleModal()
  const { dataAfterCheck, hasData, isDataEmpty } = useCheckData<VehicleUniversalData>(
    waitingFilesData,
    defaultUniversalVehicle,
  )

  const [vehicleModalActiveId, setVehicleModalActiveId] = React.useState<string>('')

  const waitingFiles: Array<VehicleUniversalData> = dataAfterCheck
  const displayedTitle = `Véhicule(s) en attente : ${hasData ? waitingFiles.length : 0}`

  // TODO: Gérer dans useGetWaitingFiles
  React.useEffect(() => {
    if (isError) {
      displayAlert('Erreur lors du chargement des dossiers en attente', 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  if (isLoading) return <Loading />
  if (isDataEmpty) return <p>Aucun véhicule en attente.</p>

  return (
    <>
      <DataTable
        columnsTitles={waitingFilesPageTableLabels}
        data={waitingFiles}
        tableTitle={displayedTitle}
        options={{
          onRowClick: (line) => {
            const typedLine = line as unknown as VehicleUniversalData
            setVehicleModalActiveId(typedLine.no)
            setOpenModal(true)
          },
        }}
      />

      <VehicleModal
        id={vehicleModalActiveId}
        onClose={() => setVehicleModalActiveId('')}
      />
    </>
  )
}

export default WaitingFilesPage
