type VehiclePricing = {
  startPrice: string
  estimation: string
  estimationExclVat: string
  quotation: string
  argusAgeAddedValue: string
  argusMileageAddedValue: string
  tvaRecuperable: boolean
}

const defaultVehiclePricing: VehiclePricing = {
  startPrice: '',
  estimation: '',
  estimationExclVat: '',
  quotation: '',
  argusAgeAddedValue: '',
  argusMileageAddedValue: '',
  tvaRecuperable: false,
}

export type { VehiclePricing }
export { defaultVehiclePricing }
