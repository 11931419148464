import { VehicleUniversalData } from 'models/universalData'

const searchResultTableLabels: Partial<Record<keyof VehicleUniversalData, string>> = {
  // TODO: Universal table Label
  no: 'Réf',
  licensePlate: 'Immatriculation',
  make: 'Marque',
  bodyType: 'Type com',
  dateDelivery: 'Entrée sur le parc',
  auctionStartDateTime: 'Date de vente',
  administrativeStatus: 'Statut général',
  auctionStatus: 'Sous-Statut',
  estimation: 'Estimation',
  argusMileageAddedValue: 'Cote Km',
  argusAgeAddedValue: 'Cote Datée',
}

export { searchResultTableLabels }
