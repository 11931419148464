import { RoleTranslationKeys, RolesPossibleValues } from 'utils/constants'
import { AddressInfo, defaultAddress } from './address'

type Functions = Array<string>

type Connection = {
  applicationName: Omit<RoleTranslationKeys, 'none' | 'buyer' | 'seller'> | ''
  firstConnection: string
  lastConnection: string
}

type ContactWithoutAddress = {
  id: string
  firstName: string
  lastName: string
  phoneNumber: string
  signInName: string
  email: string
  emails: string[]
  status: boolean
  assignedEntityId: string
  associatedEntities: string[]
  associatedEntitiesComplete: string[]
  roles: RolesPossibleValues
  functions: Functions
  representativeOf: Array<string>
  mailConsent: boolean
  smsConsent: boolean
  idCardFileStorageId: string
  createdBy: string
  createdOn: string
  createdDateTime?: string
  connections: Array<Connection>
}

type Contact = ContactWithoutAddress & {
  location: Array<AddressInfo>
}

type ContactFlattened = ContactWithoutAddress & AddressInfo

type BeforeCreateOmittedKeys = 'id' | 'status'

type ContactBeforeCreateWithoutAddress = Omit<ContactWithoutAddress, BeforeCreateOmittedKeys>
type ContactBeforeCreate = Omit<Contact, BeforeCreateOmittedKeys>
type ContactBeforeCreateFlattened = Omit<ContactFlattened, BeforeCreateOmittedKeys>

const defaultContactBeforeCreateWithoutAddress: ContactBeforeCreateWithoutAddress = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  signInName: '',
  email: '',
  emails: [],
  assignedEntityId: '',
  associatedEntitiesComplete: [],
  associatedEntities: [],
  roles: 0,
  functions: [],
  representativeOf: [],
  mailConsent: false,
  smsConsent: false,
  idCardFileStorageId: '',
  createdBy: '',
  createdOn: '',
  createdDateTime: '',
  connections: [],
}

const defaultContactBeforeCreate: ContactBeforeCreate = {
  ...defaultContactBeforeCreateWithoutAddress,
  // location
  location: [{ ...defaultAddress }],
}

const defaultContactBeforeCreateFlattened: ContactBeforeCreateFlattened = {
  ...defaultContactBeforeCreateWithoutAddress,
  // location
  ...defaultAddress,
}

const defaultContact: Contact = {
  id: '',
  status: true,
  ...defaultContactBeforeCreate,
}

const defaultContactFlattened: ContactFlattened = {
  id: '',
  status: true,
  ...defaultContactBeforeCreateFlattened,
}

export type {
  Functions,
  Contact,
  ContactFlattened,
  ContactBeforeCreate,
  ContactBeforeCreateFlattened,
  Connection,
}

export {
  defaultContact,
  defaultContactFlattened,
  defaultContactBeforeCreate,
  defaultContactBeforeCreateFlattened,
}
