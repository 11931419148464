import { AuctionLineHistory, defaultAuctionLineHistory } from './auctionLineHistory'
import { AuctionLineInformation, defaultAuctionLineInformation } from './auctionLineInformation'
import { AuctionLinePricing, defaultAuctionLinePricing } from './auctionLinePricing'
import { defaultStatus, Status } from './status'
import { defaultVehicleDetail, VehicleDetail } from './vehicleDetail'
import { defaultVehiclePartial, VehiclePartial } from './vehiclePartial'

type AuctionLine = {
  no: string
  entityId: string
  contactID: string
  vehiclePartial: VehiclePartial
  vehicleDetail: VehicleDetail
  localisationInternet: unknown | null
  information: AuctionLineInformation
  prestations: []
  status: Status
  documents: []
  photos: []
  pricing: AuctionLinePricing
  subAuction: unknown | null
  passingOrderNo: number
  ligthouseLot: boolean
  import: boolean
  lotStar: boolean
  history: AuctionLineHistory
}

const defaultAuctionLine: AuctionLine = {
  no: '',
  entityId: '',
  contactID: '',
  vehiclePartial: defaultVehiclePartial,
  vehicleDetail: defaultVehicleDetail,
  localisationInternet: null,
  information: defaultAuctionLineInformation,
  prestations: [],
  status: defaultStatus,
  documents: [],
  photos: [],
  pricing: defaultAuctionLinePricing,
  subAuction: null,
  passingOrderNo: 0,
  ligthouseLot: false,
  import: false,
  lotStar: false,
  history: defaultAuctionLineHistory,
}
export type { AuctionLine }
export { defaultAuctionLine }
