/* eslint-disable camelcase */
// type UserB2C = {
//   emails: Array<string>
//   family_name: string
//   given_name: string
// }

// type UserB2B = {
//   // unique_name is an email
//   unique_name: string
//   name: string
// }

type UserRoles = 'anonymous' | 'authenticated'

type ClientPrincipal = null | {
  claims: Array<unknown>
  identityProvider: 'aad' | 'b2c'
  userDetails: string
  userId: string
  userRoles: Array<UserRoles>
}

type AuthenticatedUser = { clientPrincipal: ClientPrincipal }

const defaultAuthenticatedUser: AuthenticatedUser = {
  clientPrincipal: null,
}

// export type { AuthenticatedUser, UserB2C, UserB2B }
export type { AuthenticatedUser }
export { defaultAuthenticatedUser }
