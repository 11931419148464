import { VehicleUniversalData } from 'models/universalData'

type WaitingFilesPageTableLabels = Partial<Record<keyof VehicleUniversalData, string>>

const waitingFilesPageTableLabels: WaitingFilesPageTableLabels = {
  // assign "undefined" to property if you don't want it to appear in data table
  blockingReason: 'Raison',
  nbJ: 'NJ',
  no: 'Réf',
  licensePlate: 'Immat',
  bodyType: 'Type Commercial',
  make: 'Marque',
  model: 'Modèle',
  proprietaireDebiteur: 'Propriétaire / déb',
  dateProduction: 'Date création',
}

export type { WaitingFilesPageTableLabels }
export { waitingFilesPageTableLabels }
