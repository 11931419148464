type AuctionLinePricing = {
  startPriceTFC: string
  startPriceHF: string
  reserveHF: string
  reserveTFC: string
  achatImmediat: string
  coteArgusPT: string
  coteArgusKm: string
  coteMarche: string
  prixNeufHt: string
  prixNeufTTC: string
  estimationVPAuto: string
  estimationApporteur: string
  bidPriceIncrement: string
  tarifAcheteurCodeE: string
  tarifAcheteurE: string
  tvaRecuperable: boolean
}

const defaultAuctionLinePricing: AuctionLinePricing = {
  startPriceTFC: '',
  startPriceHF: '',
  reserveHF: '',
  reserveTFC: '',
  achatImmediat: '',
  coteArgusPT: '',
  coteArgusKm: '',
  coteMarche: '',
  prixNeufHt: '',
  prixNeufTTC: '',
  estimationVPAuto: '',
  estimationApporteur: '',
  bidPriceIncrement: '',
  tarifAcheteurCodeE: '',
  tarifAcheteurE: '',
  tvaRecuperable: false,
}

export type { AuctionLinePricing }
export { defaultAuctionLinePricing }
