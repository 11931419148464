// import { InteractionStatus } from '@azure/msal-browser'
// import { useMsal } from '@azure/msal-react'
import { Checkbox, FormControlLabel } from '@mui/material'
import { VPautoLogo } from 'components/logos'
// import { useMsalSetup } from 'contexts'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { LOCAL_STORAGE_KEYS, LOGIN_TYPE, LoginType, PATHS } from 'utils/constants'
import { loginTypeCookie, rememberMeCookie } from 'utils/cookies'

const SignInPage = () => {
  // const { instance: msalInstance, inProgress } = useMsal()
  // const { loginType, msalSetup } = useMsalSetup()
  // const navigate = useNavigate()

  // const isMsalInstanceInProgress = inProgress !== InteractionStatus.None

  const [isRememberMeChecked, setIsRememberMeChecked] = React.useState<boolean>(
    rememberMeCookie.get(),
  )

  const handleRememberMeChange = () => {
    setIsRememberMeChecked(!isRememberMeChecked)
  }

  // const signIn = () => {
  //   try {
  //     msalInstance.loginRedirect(msalSetup.loginRequest)
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }

  const handleSignInClick = (event: React.MouseEvent<HTMLElement>, loginTypeClick: LoginType) => {
    event.preventDefault()
    rememberMeCookie.set(isRememberMeChecked)
    // navigate(PATHS.authB2B)

    // if (loginType === loginTypeClick) {
    //   signIn()
    //   return
    // }
  }

  // localStorage.setItem(LOCAL_STORAGE_KEYS.IS_LOGGING, 'true')
  // loginTypeCookie.set(loginTypeClick)

  // React.useEffect(() => {
  //   if (inProgress !== InteractionStatus.None) return

  //   const isLogging = Boolean(localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LOGGING))
  //   if (isLogging) {
  //     localStorage.removeItem(LOCAL_STORAGE_KEYS.IS_LOGGING)
  //     signIn()
  //     return
  //   }
  //   if (isRememberMeChecked) signIn()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [inProgress])


  return (
    <div className="flex flex-col items-center w-screen h-screen">
      <div className="flex flex-col items-center justify-around w-1/2 m-auto h-2/5">
        <VPautoLogo />
        <p className="text-5xl font-bold text-secondary">Extranet VPauto</p>
        <p>Vous devez vous connecter afin d&apos;accéder aux fonctionnalités.</p>
        <FormControlLabel
          label="Se souvenir de moi"
          // disabled={isMsalInstanceInProgress}
          control={
            <Checkbox
              name="mailConsent"
              color="secondary"
              checked={isRememberMeChecked}
              onChange={handleRememberMeChange}
            />
          }
        />
        {/* <button
          type="button"
          className="w-1/2 h-10 text-white rounded-md bg-primary-main disabled:bg-slate-300"
          onClick={(event) => handleSignInClick(event, LOGIN_TYPE.B2C)}
          // disabled={isMsalInstanceInProgress}
        >
        </button> */}

        <a
          href={PATHS.authB2C}
          className="flex items-center justify-center w-1/2 h-10 text-center text-white rounded-md bg-primary-main disabled:bg-slate-300"
          // onClick={(event) => handleSignInClick(event, LOGIN_TYPE.B2B)}
        >
          Se connecter en tant qu&apos;apporteur
        </a>

        <a
          href={PATHS.authB2B}
          className="underline underline-offset-2 text-secondary disabled:text-slate-300"
          // onClick={(event) => handleSignInClick(event, LOGIN_TYPE.B2B)}
        >
          Se connecter en tant qu&apos;Admin VP Auto
        </a>
      </div>
    </div>
  )
}

export default SignInPage
