import React from 'react'
import { Fade, Modal, Box, ModalProps } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const style = {
  position: 'absolute',
  right: '0px',
  width: 'calc(100% )',
  height: '100%',
  bottom: 0,
  bgcolor: 'background.paper',
  p: 0,
}

type DisplayedFullScreenModalProps = {
  onClose?: () => void
} & Omit<
  ModalProps,
  'aria-labelledby' | 'aria-describedby' | 'open' | 'onClose' | 'closeAfterTransition'
> & {
    children: React.JSX.Element
  }

const useFullScreenModal = () => {
  const [openModal, setOpenModal] = React.useState(false)

  const handleCloseModal = () => setOpenModal(false)

  const DisplayedFullScreenModal = ({
    onClose,
    children,
    ...otherProps
  }: DisplayedFullScreenModalProps) => {
    const handleClose = () => {
      onClose?.()
      handleCloseModal()
    }

    // FIXME: Props drilling with children.props.isCarouselFull
    return (
      <div>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          disableEscapeKeyDown={children.props.isCarouselFull}
          {...otherProps}
        >
          <Fade in={openModal}>
            <Box
              id="box"
              sx={style}
            >
              <button
               aria-label='close modal'
                type="button"
                onClick={handleClose}
                className="fixed overflow-y-auto rounded-lg bg-secondary-light right-8 top-8"
              >
                <CloseIcon sx={{ fontSize: 48 }} />
              </button>
              {children}
            </Box>
          </Fade>
        </Modal>
      </div>
    )
  }

  return {
    setOpenModal,
    DisplayedFullScreenModal,
  }
}

export default useFullScreenModal
