type SubAuction = {
  id: number
  description: string
  startIndex: number
  fromTime: string | null
  toTime: string | null
  auctionTemplate: string
}

const defaultSubAuction: SubAuction = {
  id: 0,
  description: '',
  startIndex: 0,
  fromTime: null,
  toTime: null,
  auctionTemplate: '',
}

export type { SubAuction }
export { defaultSubAuction }
