import { ApiPhotosResponse } from 'utils/api/queries/queryInterface'

const dummyPhotoResponse: ApiPhotosResponse = {
  data: {
    '2D': [
      'https://images.caradisiac.com/logos/4/3/1/1/144311/S0-Reponse-a-la-question-du-jour-n-172-quelle-etait-la-voiture-de-Gaston-Lagaffe-56653.jpg',
      'https://media.sudouest.fr/16093546/1000x500/20230727181525-uge124.jpg?v=1691147279',
      'https://s1.dmcdn.net/v/2cYAK1PPDCjOGNA9f/x1080',
      'https://www.parismatch.com/lmnr/f/webp/r/1144,763,000000,forcex,center-middle/img/var/pm/public/media/image/2022/03/08/00/Voiture-Oui-Oui.jpg?VersionId=YA.GhLgRpYu.2ONTMyR44c07EMn2O4xb',
      'https://noneco.wordpress.com/wp-content/uploads/2011/01/toc-blind-600.jpg',
    ],
  },
}

export { dummyPhotoResponse }
