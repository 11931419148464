type VehicleInformation = {
  mileage: null | unknown
  fre: string
}
const defaultVehicleInformation: VehicleInformation = {
  mileage: null,
  fre: '',
}

export type { VehicleInformation }
export { defaultVehicleInformation }
