import React from 'react'
import { ThemeProvider } from '@mui/material'
import { theme } from 'utils/theme'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AlertProvider } from './AlertContext'
import { ReserveQueueContextProvider } from './ReserveQueueContext'
import { EntitiesProvider } from './EntitiesContext'
import { AuctionProvider } from './AuctionContext'
import { AuthenticatedUserProvider } from './UserContext'
import { TranslatorProvider } from './StatusTranslatorContext'

const queryClient = new QueryClient()
const AppProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthenticatedUserProvider>
        <ThemeProvider theme={theme}>
          <AlertProvider>
            <EntitiesProvider>
              <AuctionProvider>
                <TranslatorProvider>
                  <ReserveQueueContextProvider>{children}</ReserveQueueContextProvider>
                </TranslatorProvider>
              </AuctionProvider>
            </EntitiesProvider>
          </AlertProvider>
        </ThemeProvider>
      </AuthenticatedUserProvider>
    </QueryClientProvider>
  )
}

export default AppProvider
