type AuctionLineInformation = {
  mileage: number
  fre: string
  taxi: boolean
  autoEcole: boolean
  premiereMain: boolean
  observationsVendeur: unknown | null
}

const defaultAuctionLineInformation: AuctionLineInformation = {
  mileage: 0,
  fre: '',
  taxi: false,
  autoEcole: false,
  premiereMain: false,
  observationsVendeur: null,
}

export type { AuctionLineInformation }
export { defaultAuctionLineInformation }
