import React from 'react'
import { VehicleSheetLabels } from 'utils/labels'

import VehicleSheetLinesGroup from '../VehicleSheetLinesGroup'

const VehicleSheetSectionModelInfo = () => {
  const items: (keyof VehicleSheetLabels)[] = ['equipments']
  // const colThree: (keyof VehicleSheetLabels)[] = ['mileage', 'estimation']

  return (
    <>
      <VehicleSheetLinesGroup
        arrayOfKeys={items}
        className="grid content-start h-full grid-cols-3 col-span-3 row-span-2 gap-2 "
      />

      {/* <VehicleSheetLinesGroup
        arrayOfKeys={colThree}
        className="grid content-around grid-cols-1 col-span-1 col-start-3 "
      /> */}
    </>
  )
}

export default VehicleSheetSectionModelInfo
