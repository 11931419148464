type VehicleDetail = {
  cylinderArrangement: null | unknown
  chargerType: null | unknown
  valvesPerCylinder: null | unknown
  roofLoad: string
  length: string
  width: string
  height: string
  wheelSize: null | unknown
  totalWeight: string
  curbWeight: string
  trunkCapacityMax: null | unknown
  trunkCapacityMed: null | unknown
  trunkCapacityMin: null | unknown
  autonomousLevelBase: null | unknown
  batteryType: null | unknown
  startSupport: boolean
  startStopSystem: boolean
  batteryCapAH: null | unknown
  batteryCapkWH: null | unknown
  pollutionNorm: null | unknown
  driveType: null | unknown
  engineType: null | unknown
  mixtureFeedSystem: null | unknown
  exhaustTreatmentSystem: null | unknown
  topSpeed: null | unknown
  acceleration: null | unknown
  tankRange: null | unknown
  consUrb: string
  consLand: string
  consOverall: string
  co2Emision: string
  tyreFront: null | unknown
  tyreRear: null | unknown
  typeSpare: null | unknown
  numberOfBatteries: null | unknown
  chargeCycles: null | unknown
  chargeTime: null | unknown
  make: string
  model: string
  modelExt: string
  version: string
  versionExt: string
  bodyType: string
  energy: string
  engineCapacity: null | unknown
  numberOfDoors: null | unknown
  numberOfGears: null | unknown
  numberOfSeats: null | unknown
  numberOfCylinders: null | unknown
  powerKw: null | unknown
  powerHp: null | unknown
  taxHp: null | unknown
  productionDateBegin: null | unknown
  productionDateEnd: null | unknown
  productionYears: null | unknown
  transmission: null | unknown
  segment1: string
  segment2: string
  newPriceExcVat: string
  newPriceIncVat: string
  id: string
}
const defaultVehicleDetail: VehicleDetail = {
  cylinderArrangement: '',
  chargerType: '',
  valvesPerCylinder: 0,
  roofLoad: '',
  length: '',
  width: '',
  height: '',
  wheelSize: '',
  totalWeight: '',
  curbWeight: '',
  trunkCapacityMax: 0,
  trunkCapacityMed: 0,
  trunkCapacityMin: 0,
  autonomousLevelBase: null,
  batteryType: null,
  startSupport: false,
  startStopSystem: false,
  batteryCapAH: 0,
  batteryCapkWH: 0,
  pollutionNorm: null,
  driveType: '',
  engineType: '',
  mixtureFeedSystem: '',
  exhaustTreatmentSystem: '',
  topSpeed: 0,
  acceleration: '',
  tankRange: 0,
  consUrb: '',
  consLand: '',
  consOverall: '',
  co2Emision: '',
  tyreFront: '',
  tyreRear: '',
  typeSpare: '',
  numberOfBatteries: 0,
  chargeCycles: 0,
  chargeTime: 0,
  make: '',
  model: '',
  modelExt: '',
  version: '',
  versionExt: '',
  bodyType: '',
  energy: '',
  engineCapacity: 0,
  numberOfDoors: 0,
  numberOfGears: 0,
  numberOfSeats: 0,
  numberOfCylinders: 0,
  powerKw: 0,
  powerHp: 0,
  taxHp: 0,
  productionDateBegin: '',
  productionDateEnd: '',
  productionYears: '',
  transmission: null,
  segment1: '',
  segment2: '',
  newPriceExcVat: '',
  newPriceIncVat: '',
  id: '',
}

export type { VehicleDetail }
export { defaultVehicleDetail }
