type AuctionLineHistory = {
  prixRetraitDerniereVente: string | null
  derniereOffreVentePhysique: string | null
  derniereOffreVenteElectro: string | null
  nombrePassageVente: string | null
}

const defaultAuctionLineHistory: AuctionLineHistory = {
  prixRetraitDerniereVente: null,
  derniereOffreVentePhysique: null,
  derniereOffreVenteElectro: null,
  nombrePassageVente: null,
}

export type { AuctionLineHistory }
export { defaultAuctionLineHistory }
