import { useQuery } from '@tanstack/react-query'
import { Entity } from 'models/erp'

import { currentQueryService } from 'utils/api/queries'

const useGetAllEntities = () => {
  const query = useQuery<Array<Entity>, Error>({
    queryKey: ['useGetAllEntities'],
    queryFn: () => currentQueryService.getAllEntities(),
  })
  return query
}

export { useGetAllEntities }
