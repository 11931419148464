import { SellerDTO, familyAllSellersCardName } from 'models'

class OdataFilterBuilder {
  private query: string = ''
  // private query: string = ''

  add(condition: string): OdataFilterBuilder {
    this.query += condition
    return this
  }

  entityIdFilter(entityId: string): OdataFilterBuilder {
    const filter: string = `entityId eq '${entityId}'`
    this.query += filter
    return this
  }

  timeLineFilter(timeLine: 'past' | 'future'): OdataFilterBuilder {
    const now = new Date()
    const year = now.getUTCFullYear()
    const month = String(now.getUTCMonth() + 1).padStart(2, '0') // Months are 0-based
    const day = String(now.getUTCDate()).padStart(2, '0')
    const today = `${year}-${month}-${day}T00:00:00Z`
    // eslint-disable-next-line max-len
    const filter: string = `auction/auctionStartDateTime ${timeLine === 'future' ? 'gt' : 'lt'} '${today}'`
    this.query += filter
    return this
  }

  and(condition?: string): OdataFilterBuilder {
    const filter: string = condition === undefined ? ` and ` : ` and ${condition}`
    this.query += filter
    return this
  }

  or(condition?: string): OdataFilterBuilder {
    const filter: string = condition === undefined ? ` or ` : ` or ${condition}`
    this.query += filter
    return this
  }

  addCode(seller: SellerDTO): OdataFilterBuilder {
    const filterToAdd =
      seller.cardName === familyAllSellersCardName
        ? `CardCodeFamille eq '${seller.cardCodeFamille}'`
        : `CardCode eq '${seller.cardCode}'`

    this.query += filterToAdd
    return this
  }

  build(): string {
    return this.query
  }
}

export { OdataFilterBuilder }
