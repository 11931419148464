import { useQueries } from '@tanstack/react-query'
import { useEntities } from 'contexts'
import { AuctionLightDTO } from 'models/erp'
import { currentQueryService } from 'utils/api/queries'
import { generateAuctionLib, isEntityDefault } from 'utils/helpers'

const useGetAuctionsLight = (timeLine: 'past' | 'future') => {
  const { filteringEntity, childrenEntities, selectedChild } = useEntities()
  const allFilteringEntities = isEntityDefault(selectedChild)
    ? [filteringEntity, ...childrenEntities]
    : [selectedChild]

  const results = useQueries({
    queries: allFilteringEntities.map((entity) => ({
      queryKey: ['useGetAuctionsLight', entity, timeLine],
      queryFn: () => {
        if (isEntityDefault(entity)) return Promise.resolve()
        return currentQueryService.getAuctionsLight(entity, timeLine)
      },
      retry: false,
    })),
  })

  const isLoading = results.some((result) => result.isLoading)
  const isError = results.some((result) => result.isError)
  const rawData = isError
    ? []
    : results
        .filter((result) => result.data !== undefined)
        .reduce((accumulator: AuctionLightDTO[], result) => {
          return [...accumulator, ...(result.data || [])]
        }, [])

  const data = rawData.map((auctionLight) => generateAuctionLib(auctionLight))

  return { data, isLoading, isError }
}

export { useGetAuctionsLight }
