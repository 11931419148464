// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Entity, Vehicle, VehicleDocument } from 'models/erp'
import { dummyQueryAdapter } from './dummyQueryAdapter'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { queryAdapter } from './queryAdapter'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { queryAdapterMockedReserveUpdate } from './queryAdapterMockedReserveUpdate'
import { IQueries, UpdateVehicleReserveParams } from './queryInterface'

const queryService = (adapter: IQueries): IQueries => ({
  getContact: async (contactId: string) => adapter.getContact(contactId),

  getAllEntities: () => adapter.getAllEntities(),

  getChildren: (parentEntity: Entity) => adapter.getChildren(parentEntity),

  getAuction: async (auctionNo: string) => adapter.getAuction(auctionNo),

  getAuctionsLight: (filteringEntity: Entity, timeLine) =>
    adapter.getAuctionsLight(filteringEntity, timeLine),

  getWaitingFilesFromEntity: async (filteringEntity: Entity) =>
    adapter.getWaitingFilesFromEntity(filteringEntity),

  getStockFromEntity: async (filteringEntity: Entity) =>
    adapter.getStockFromEntity(filteringEntity),

  getVehicles: async (filteringEntity: Entity, key: 'no' | 'licensePlate' | 'vin', value: string) =>
    adapter.getVehicles(filteringEntity, key, value),

  getStatusesObject: async () => adapter.getStatusesObject(),

  getVehicleByNo: (no: Vehicle['no']) => adapter.getVehicleByNo(no),

  // getFileStorageUrl: async (fileStorageId: string) => adapter.getFileStorageUrl(fileStorageId),
  // getFilesStorageUrl: async () => adapter.getFilesStorageUrl(),

  // Old stuff

  getVehicleByImmat: (vehicleImmat: string) => adapter.getVehicleByImmat(vehicleImmat),

  updateVehicleReserve: ({ vehicleProject, newReserve }: UpdateVehicleReserveParams) =>
    adapter.updateVehicleReserve({ vehicleProject, newReserve }),
  getPhotos: (immat: string) => adapter.getPhotos(immat),
})

const currentQueryService = queryService(dummyQueryAdapter)

export { queryService, currentQueryService }
