import dayjs from 'dayjs'
import { FutureSaleVehicleDTO, SaleResultVehicleDTO, VehicleSheetDTO } from 'models'

const dateKeysToFormat: Array<
  | keyof Partial<FutureSaleVehicleDTO>
  | keyof Partial<SaleResultVehicleDTO>
  | keyof Partial<VehicleSheetDTO>
> = [
  'date1MEC',
  'dateLivraison',
  'dateMission',
  'dateSortie',
  'dateVenteAffectee',
  'dateVenteAffecteeE',
  'dateDerniereCotation',
]

const isDateKeyToFormat = (key: string) => {
  return (dateKeysToFormat as Array<string>).includes(key)
}

const formatDate = (date: string): string => {
  return dayjs(date).format('DD/MM/YY')
}

const formatDatesInObject = <T extends Record<string, unknown>, R extends Record<string, unknown>>(
  obj: T,
): R => {
  const dateKeys: Array<keyof Partial<T>> = Object.keys(obj).filter((objKey) =>
    isDateKeyToFormat(objKey),
  )
  const onlyObjFormattedDates = dateKeys.reduce((prev, dateKey) => {
    const currentDate = obj[dateKey]

    if (currentDate === null) return { ...prev, [dateKey]: '' }
    if (currentDate === '0') return { ...prev, [dateKey]: '' }
    const date = formatDate(currentDate as string)
    return { ...prev, [dateKey]: date }
  }, {}) as Record<keyof Partial<T>, string>

  const objFormattedDates = { ...obj, ...onlyObjFormattedDates } as R

  return objFormattedDates
}
// ////

export { isDateKeyToFormat, formatDate, formatDatesInObject }
