import { defaultVehicle, Vehicle } from 'models/erp'
import { VehicleUniversalData } from 'models/universalData'
import { formatDateIsoToFr } from '../format'

const extractUniversalVehicle = (line: Vehicle) => {
  const universalData: VehicleUniversalData = {
    blockingReason: line.status.blockingReason,
    nbJ: 'TBD',
    no: line.no,
    licensePlate: line.vehiclePartial?.licensePlate,
    bodyType: line.vehiclePartial.bodyType,
    model: line.vehicleDetail.model,
    make: line.vehicleDetail.make,
    proprietaireDebiteur: 'TBD',
    dateProduction: line.vehiclePartial.dateProduction,
    dateDelivery: line.vehiclePartial.dateDelivery,
    locationName: line.location?.locationName || '-',
    auctionStatus: line.status.auctionStatus,
    administrativeStatus: line.status.administrativeStatus,
    auctionStartDateTime: line.auction?.auctionStartDateTime
      ? formatDateIsoToFr(line.auction?.auctionStartDateTime)
      : '-',
    entityId: line.entityId,
    vin: line.vehiclePartial.vin,
    dateFirstReg: line.vehiclePartial.dateFirstReg,
    color: line.vehiclePartial.color,
    typeRegDoc: line.vehiclePartial.typeRegDoc,
    fuelType: line.vehiclePartial.fuelType,
    bodyTypeRegDoc: line.vehiclePartial.bodyTypeRegDoc,
    numberOfSeats: line.vehicleDetail.numberOfSeats,
    equipments: line.vehiclePartial.equipments,
    typeSpare: line.vehicleDetail.typeSpare,
    version: line.vehicleDetail.version,
    taxHp: line.vehicleDetail.taxHp,
    providedMileage: line.vendorInformation?.providedMileage || 0,
    providedFRE: line.vendorInformation?.providedFRE || '-',
    priceReserve: line.vendorInformation?.priceReserve || '-',
    mileage: line.information.mileage,
    estimation: line.pricing.estimation,
    argusMileageAddedValue: line.pricing.argusMileageAddedValue,
    argusAgeAddedValue: line.pricing.argusAgeAddedValue,
    documents: line.documents,
    photos: line.photos,
  }
  return universalData
}

const defaultUniversalVehicle = extractUniversalVehicle(defaultVehicle)

const extractUniversalVehicles = (data: Array<Vehicle>) => {
  return data.map((vh: Vehicle) => extractUniversalVehicle(vh))
}

export { extractUniversalVehicle, extractUniversalVehicles }
export { defaultUniversalVehicle }
