import { AuctionLightDTO, defaultAuctionLightDTO } from 'models/erp'

type AuctionLightOverload = AuctionLightDTO & { lib: string }

const defaultAuctionLightOverLoad: AuctionLightOverload = {
  ...defaultAuctionLightDTO,
  lib: '',
}

export type { AuctionLightOverload }
export { defaultAuctionLightOverLoad }
