import { useVehicle } from 'contexts'
import { VehicleSheetOverLoad } from 'models'
import React from 'react'
import { ObservationPopUp } from 'components/common'
import { VehicleSheetLabels, vehicleSheetLabels } from 'utils/labels'
import { formatAgeDaysToMonths, formatTimeFromNumber } from 'utils/helpers/format'
import { theme } from 'utils/theme'
import { VehicleUniversalData } from 'models/universalData'
import { parcStockageDictionnary } from 'utils/translations'
import VehicleSheetScanWithStatus from '../VehicleSheetScanWithStatus'
import VehicleSheetScanWithoutStatus from '../VehicleSheetScanWithoutStatus'

type VehicleSheetLinesGroupProps = { arrayOfKeys: (keyof VehicleSheetLabels)[]; className?: string }

const VehicleSheetLinesGroup = ({ arrayOfKeys, className }: VehicleSheetLinesGroupProps) => {
  const currentVehicle = useVehicle()

  const customStyle = {
    fontSize: '1.8rem',
    display: 'block',
    width: 'auto',
    height: 'auto',
    background: 'transparent',
    boxShadow: 'none',
    padding: 0,
    margin: 0,
    color: 'black',
    marginLeft: '0.5rem',
    minWidth: 'unset',
  }

  type Display = {
    [key in keyof Partial<VehicleSheetLabels>]: React.ReactNode
  }

  // const displaySaleDateAndHour = (date: string, hour: string) => {
  //   if (!date) return '-'
  //   if (!hour) return date
  //   return `${date} à ${hour}`
  // }

  const display: Display = {
    // TODO: adapt and delete
    // scanCG: (
    //   <VehicleSheetScanWithStatus
    //     scanType="scanCG"
    //     key="scanCG"
    //   />
    // ),
    // scanCT: (
    //   <VehicleSheetScanWithStatus
    //     scanType="scanCT"
    //     key="scanCT"
    //   />
    // ),
    // suiviEntretien: (
    //   <VehicleSheetScanWithStatus
    //     scanType="suiviEntretien"
    //     key="suiviEntretien"
    //   />
    // ),
    // urlBE: (
    //   <VehicleSheetScanWithoutStatus
    //     property="urlBE"
    //     key="urlBE"
    //   />
    // ),
    // urlET: (
    //   <VehicleSheetScanWithoutStatus
    //     property="urlET"
    //     key="urlET"
    //   />
    // ),
    // observationsVendeur: (
    //   <div
    //     className="p-4 mb-2 overflow-y-auto bg-white border border-black rounded-md h-36"
    //     key="observationsVendeur"
    //   >
    //     <span className="font-bold">{vehicleSheetLabels.observationsVendeur}</span>
    //     <br />
    //     {currentVehicle.observationsVendeur}
    //   </div>
    // ),
    // etat: (
    //   <div
    //     className="grid w-full grid-cols-12 my-2"
    //     key="etat"
    //   >
    //     <span className="flex items-center col-span-7 font-bold">{vehicleSheetLabels.etat}</span>
    //     <ObservationPopUp
    //       observationString={currentVehicle.etat ? currentVehicle.etat.toString() : ''}
    //       customButtonStyle={{
    //         ...customStyle,
    //         ...(currentVehicle.etat ? {} : { color: theme.palette.grey }),
    //       }}
    //       disabled={!currentVehicle.etat}
    //     />
    //   </div>
    // ),
    // memoExterne: (
    //   <div
    //     className="grid w-full grid-cols-12 my-2 "
    //     key="memoExterne"
    //   >
    //     <span className="flex items-center col-span-7 font-bold">
    //       {vehicleSheetLabels.memoExterne}
    //     </span>
    //     <ObservationPopUp
    //       observationString={
    //         currentVehicle.memoExterne ? currentVehicle.memoExterne.toString() : ''
    //       }
    //       customButtonStyle={{
    //         ...customStyle,
    //         ...(currentVehicle.memoExterne ? { color: 'black' } : { color: theme.palette.grey }),
    //       }}
    //       disabled={!currentVehicle.memoExterne}
    //     />
    //   </div>
    // ),
    // equipementsPrint: (
    //   <div
    //     className="p-4 mb-2 overflow-y-auto bg-white border border-black rounded-md h-36"
    //     key="equipements"
    //   >
    //     <span className="font-bold">{vehicleSheetLabels.equipementsPrint}</span>
    //     <br />
    //     {currentVehicle.equipementsPrint}
    //   </div>
    // ),
    // noteVisuelle: (
    //   <p
    //     key="noteVisuelle"
    //     className="text-primary-main"
    //   >
    //     <span className="font-bold"> {vehicleSheetLabels.noteVisuelle}</span>{' '}
    //     {currentVehicle.noteVisuelle}
    //   </p>
    // ),
    // expertise: (
    //   <p
    //     key="expertise"
    //     className="text-primary-main"
    //   >
    //     <span className="font-bold"> {vehicleSheetLabels.expertise}</span>{' '}
    //     {currentVehicle.expertise}
    //   </p>
    // ),
    // interieur: (
    //   <p
    //     key="interieur"
    //     className="text-primary-main"
    //   >
    //     <span className="font-bold"> {vehicleSheetLabels.interieur}</span>{' '}
    //     {currentVehicle.interieur}
    //   </p>
    // ),
    // carnet: (
    //   <p key="carnet">
    //     <span className="font-bold">{vehicleSheetLabels.carnet}</span>{' '}
    //     {currentVehicle.carnet ? 'Oui' : 'Non'}
    //   </p>
    // ),
    // age: (
    //   <p key="age">
    //     <span className="font-bold">{vehicleSheetLabels.age}</span>
    //     {formatAgeDaysToMonths(currentVehicle.age)}
    //   </p>
    // ),
    // dateVenteAffectee: (
    //   <p key="dateVenteAffectee">
    //     <span className="font-bold">{vehicleSheetLabels.dateVenteAffectee}</span>{' '}
    //     {displaySaleDateAndHour(
    //       currentVehicle.dateVenteAffectee,
    //       formatTimeFromNumber(currentVehicle.heure),
    //     )}
    //   </p>
    // ),
    // dateVenteAffecteeE:
    //   currentVehicle.dateVenteAffecteeE !== '' ? (
    //     <p key="dateVenteAffecteeE">
    //       <span className="font-bold">{vehicleSheetLabels.dateVenteAffecteeE}</span>{' '}
    //       {displaySaleDateAndHour(
    //         currentVehicle.dateVenteAffecteeE,
    //         formatTimeFromNumber(currentVehicle.heureE),
    //       )}
    //     </p>
    //   ) : (
    //     ''
    //   ),
    // pcentCoteKm: (() => {
    //   const hasEstimation = Boolean(currentVehicle.estimation)
    //   const canComputePercentageCK = hasEstimation && Boolean(currentVehicle.coteKm)
    //   const percentCK = canComputePercentageCK
    //     ? `${Math.round(
    //         ((currentVehicle.estimation as number) / (currentVehicle.coteKm as number)) * 100,
    //       ).toString()}%`
    //     : null
    //   return (
    //     <p key="pcentCoteKm">
    //       <span className="font-bold">{vehicleSheetLabels.pcentCoteKm}</span> {percentCK}
    //     </p>
    //   )
    // })(),
    // pcentCoteDatee: (() => {
    //   const hasEstimation = Boolean(currentVehicle.estimation)
    //   const canComputePercentageCPerso = hasEstimation && Boolean(currentVehicle.cotePerso)
    //   const percentPTerso = canComputePercentageCPerso
    //     ? `${Math.round(
    //         ((currentVehicle.estimation as number) / (currentVehicle.cotePerso as number)) * 100,
    //       ).toString()}%`
    //     : null
    //   return (
    //     <p key="pcentCoteDatee">
    //       <span className="font-bold">{vehicleSheetLabels.pcentCoteDatee}</span> {percentPTerso}
    //     </p>
    //   )
    // })(),
    // codeParcStockage: (
    //   <p key="codeParkStockage">
    //     <span className="font-bold"> {vehicleSheetLabels.codeParcStockage}</span>
    //     {(currentVehicle.codeParcStockage !== null &&
    //       parcStockageDictionnary[currentVehicle.codeParcStockage]) ||
    //       'Code non reconnu'}
    //   </p>
    // ),

    equipments: (
      <div>
        <span className="font-bold">Equipements: </span>
        {currentVehicle.equipments.map((equ) => {
          return <p key={equ.name}>{equ.name}</p>
        })}
      </div>
    ),
  }

  return (
    <div className={className}>
      {arrayOfKeys.map((key) => {
        // const result = currentVehicle[key]

        return display[key] ? (
          display[key]
        ) : (
          <p key={key}>
            <span className="font-bold">{vehicleSheetLabels[key]}</span>
            {currentVehicle[key] as string}
          </p>
        )
      })}
    </div>
  )
}

export default VehicleSheetLinesGroup
