import { useQuery } from '@tanstack/react-query'
import { useAuthenticatedUser } from 'contexts'
import { Contact } from 'models/erp'
import { currentQueryService } from 'utils/api/queries'

const useGetContact = () => {
  const { authenticatedUser } = useAuthenticatedUser()
  const userId = authenticatedUser.clientPrincipal?.userId
  // const userId = '25bc6011-5bf2-4133-9697-9a045bcab622'

  const query = useQuery<Contact, Error>({
    queryKey: ['useGetContact', userId],
    queryFn: () => currentQueryService.getContact(userId || ''),
  })
  return query
}

export { useGetContact }
