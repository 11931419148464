import { VehicleUniversalData } from 'models/universalData'

type VehicleSheetLabels = Partial<Record<keyof VehicleUniversalData, string>>

const vehicleSheetLabels: VehicleSheetLabels = {
  no: 'Réf.int dossier: ',
  entityId: 'Réf entité: ',
  licensePlate: 'Immatriculation: ',
  vin: 'vin: ',
  dateFirstReg: 'Date première immat.: ',
  color: 'Couleur: ',
  typeRegDoc: 'Type carte grise: ',
  bodyTypeRegDoc: 'Type carrosserie: ',
  fuelType: 'Type carburant: ',
  numberOfSeats: 'Nb sièges: ',
  equipments: 'Equipements: ',
  typeSpare: 'Type roue de secours: ',
  make: 'Marque: ',
  version: 'Version: ',
  taxHp: 'Puissance fisc: ',
  locationName: 'Lieu de stockage: ',
  providedMileage: 'Kilométrage vendeur: ',

  providedFRE: 'FRE: ',
  priceReserve: 'Prix de réserve: ',
  mileage: 'Kilométrage officiel: ',
  estimation: 'Estimation: ',
  argusAgeAddedValue: 'Cote argus datée: ',
  argusMileageAddedValue: 'Cote argus kilométrique: ',
  auctionStatus: 'Statut vente: ',
  administrativeStatus: 'Statut admin.: ',
}

export { vehicleSheetLabels }
export type { VehicleSheetLabels }
