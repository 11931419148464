import { Entity } from 'models/erp'
import { entityDisplayName } from './entityDisplayName'

const sortEntities = (entitiesArray: Array<Entity>) => {
  return entitiesArray.sort((a, b) =>
    entityDisplayName(a)
      .toLowerCase()
      .localeCompare(entityDisplayName(b).toLowerCase(), undefined, {
        numeric: false,
        sensitivity: 'base',
      }),
  )
}

export { sortEntities }
