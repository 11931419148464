const PATHS = {
  home: '/',
  signIn: 'sign-in',
  userInfo: '/.auth/me',
  authB2B: '/.auth/login/aad',
  authB2C: '/.auth/login/b2c',
  logout: '/.auth/logout',
  authorizationCallback: 'authorization/callback',

  futureSales: 'future-sales',
  futureSalesDetails: 'future-sales/:auctionNo',

  waitingFiles: 'waiting-files',

  salesResult: 'sales-result',
  salesResultDetails: 'sales-result/:auctionNo',

  stock: 'stock',
  search: 'search',
} as const

type PathsKeys = keyof typeof PATHS
type Paths = (typeof PATHS)[PathsKeys]

export { PATHS }
export type { PathsKeys, Paths }
