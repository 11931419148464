import {
  Status,
  defaultStatus,
  dummyTranslator,
  Translator,
  loadingStatus,
  errorStatus,
  StatusEnum,
  Vehicle,
  defaultVehicle,
} from 'models/erp'
import React from 'react'
import { useGetStatusesTranslator } from 'utils/hooks/queries/useGetStatusesTranslator'
import { useAlert } from './AlertContext'

type StatusTranslatorContextType = {
  translator: Translator | undefined
  isTranslatorLoading: boolean
  isTranslatorError: boolean
  translateStatus: (status: Status) => Status
  translateStatusVehicle: (vehicle: Vehicle) => Vehicle
  translateStatusVehicleArray: (array: Array<Vehicle>) => Array<Vehicle>
  translateStatusByKey: (translateKey: string, valueToTranslate: string) => string
}
// TODO: Fix problem on fetching translator as an object

const StatusTranslatorContext = React.createContext<StatusTranslatorContextType>({
  translator: dummyTranslator,
  isTranslatorError: false,
  isTranslatorLoading: true,
  translateStatus: () => defaultStatus,
  translateStatusVehicle: () => defaultVehicle,
  translateStatusVehicleArray: () => [defaultVehicle],
  translateStatusByKey: () => '',
})

const TranslatorProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const { data: translator, isLoading, isError } = useGetStatusesTranslator()
  const { displayAlert } = useAlert()

  React.useEffect(() => {
    if (isError) {
      displayAlert('Error fetching translator', 'error')
    }
  }, [isError])

  const translateStatus = (status: Status) => {
    if (isLoading) {
      return loadingStatus
    }
    if (isError) {
      return errorStatus
    }
    if (translator) {
      const newStatus = { ...status }
      Object.keys(status).forEach((typeOfStatus) => {
        if (typeOfStatus !== 'blockingReason') {
          const numValueStringyfied = status[typeOfStatus as keyof typeof status]?.toString()
          const matchingTranslationTable = translator.enums.find(
            (value) => value.name === typeOfStatus,
          )
          if (matchingTranslationTable) {
            // Get the title from the matching translation table
            const answer = matchingTranslationTable.values
              .filter((translation) => translation.value === numValueStringyfied)
              .map((translation) => translation.title)[0]
            // Set status with translated title
            newStatus[typeOfStatus as keyof typeof newStatus] = answer
          } else {
            newStatus[typeOfStatus as keyof typeof newStatus] = '!'
          }
        }
      })
      return newStatus
    }
    return status
  }

  const translateStatusVehicle = (vehicle: Vehicle) => {
    if (!vehicle) {
      return defaultVehicle
    }
    const newStatus = translateStatus(vehicle.status)
    return { ...vehicle, status: newStatus }
  }

  const translateStatusVehicleArray = (array: Array<Vehicle>) => {
    const result = array.map((line) => {
      return translateStatusVehicle(line)
    })
    return result
  }

  const translateStatusByKey = (translateKey: string, valueToTranslate: string) => {
    const targetEnumValues = translator?.enums.map((translateEnum) => {
      if (translateEnum.name === translateKey) {
        return translateEnum
      }
      return null
    })[0]?.values

    if (!targetEnumValues) {
      return valueToTranslate
    }
    const translation = targetEnumValues?.map((val) => {
      if (val.value === valueToTranslate) {
        return val.title
      }
      return null
    })[0]

    return translation || valueToTranslate
  }

  const providerValue = React.useMemo(
    () => ({
      translator,
      isTranslatorLoading: isLoading,
      isTranslatorError: isError,
      translateStatus,
      translateStatusVehicle,
      translateStatusVehicleArray,
      translateStatusByKey,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [translator, isError, isLoading],
  )

  return (
    <StatusTranslatorContext.Provider value={providerValue}>
      {children}
    </StatusTranslatorContext.Provider>
  )
}

const useTranslator = () => React.useContext(StatusTranslatorContext)

export { StatusTranslatorContext, TranslatorProvider, useTranslator }
