import { Entity } from 'models/erp'

const entityDisplayName = (ent: Entity, family?: boolean) => {
  const isFamily = family === true && ent.childrenCount > 0

  const displayedName =
    ent?.name || ent?.commercialName || `${ent.firstName} ${ent.lastName}` || 'no Name!'

  if (isFamily) {
    return `${displayedName} (famille)`
  }
  return displayedName
}

export { entityDisplayName }
