import React from 'react'
import SearchIcon from '@mui/icons-material/Search'

type ImmatSearchInputProps = {
  onSearch: (immat: string) => void
}

const ImmatSearchInput = ({ onSearch }: ImmatSearchInputProps) => {
  const [inputContent, setInputContent] = React.useState('')

  const handleSearch = () => {
    onSearch(inputContent)
  }

  return (
    <div className="flex justify-between p-1 mb-8 border-2 rounded-lg shadow-md w-80 hover:shadow-lg border-primary-main bg-secondary-light">
      <input
        type="text"
        placeholder="Recherche par immat..."
        onChange={(e) => {
          setInputContent(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch()
          }
        }}
        className="w-full px-3 m-1 text-lg text-center border rounded-lg outline-none focus:border-2 focus:border-primary-main "
      />
      <button
        aria-label='search immat'
        type="button"
        onClick={handleSearch}
        className="p-2 m-1 text-lg border rounded-lg bg-secondary-main hover:bg-secondary-dark text-secondary-contrastText"
      >
        <SearchIcon fontSize="large" />
      </button>
    </div>
  )
}

export default ImmatSearchInput
