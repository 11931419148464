import { FutureAuctionVehicleViewModel } from 'models/viewmodels'

type FutureAuctionVehicleViewModelsPropertiesToDisplay = Omit<FutureAuctionVehicleViewModel, 'id'>

type FutureAuctionVehiclesTableLabels = Record<
  keyof FutureAuctionVehicleViewModelsPropertiesToDisplay,
  string
>

const futureAuctionVehiclesTableLabels: FutureAuctionVehiclesTableLabels = {
  passingOrderNo: 'Ord.',
  licensePlate: 'Immat.',
  color: 'Coul',
  type: 'Ge',
  no: 'Réf.',
  dateFirstReg: 'MEC',
  version: 'Type Commercial',
  mileage: 'KM',
  coteArgusPT: 'PT',
  coteArgusKm: 'CK',
  derniereOffreVentePhysique: 'Ret. Physique',
  derniereOffreVenteElectro: 'Ret. Electro',
  nombrePassageVente: 'NP',
  reserveHF: 'Reserve',
  estimationVPAuto: 'Est.',
  percentPT: '%PT',
  percentCK: '%CK',
  tvaRecuperable: 'TVA',
  observationsVendeur: 'Observat°',
}

// export type { FutureAuctionVehicleViewModel }
export { futureAuctionVehiclesTableLabels }
