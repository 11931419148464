/* eslint-disable no-case-declarations */
import React from 'react'
import { useGetAllEntities } from 'utils/hooks/queries/useGetAllEntities'
import { sortEntities } from 'utils/helpers/sortEntities'
import { Entity, defaultEntity } from 'models/erp'

type EntitiesReducerState = {
  allEntities: Array<Entity>
  selectedParent: Entity
  childrenEntities: Array<Entity>
  selectedChild: Entity
  filteringEntity: Entity
}

const defaultEntitiesReducerState: EntitiesReducerState = {
  allEntities: [],
  childrenEntities: [],
  selectedParent: defaultEntity,
  selectedChild: defaultEntity,
  filteringEntity: defaultEntity,
}

const entitiesActions = {
  SET_ALL_PARENT_ENTITIES: 'SET_ALL_PARENT_ENTITIES',
  SET_CHILDREN_ENTITES: 'SET_CHILDREN_ENTITES',
  SET_SELECTED_PARENT: 'SET_SELECTED_PARENT',
  SET_SELECTED_CHILD: 'SET_SELECTED_CHILD',
  SET_FILTERING_ENTITY: 'SET_FILTERING_ENTITY',
  REMOVE_SELECTED_PARENT: 'REMOVE_SELECTED_PARENT',
  REMOVE_SELECTED_CHILD: 'REMOVE_SELECTED_CHILD',
  REMOVE_FILTERING_ENTITY: 'REMOVE_FILTERING_ENTITY',
} as const

type EntitiesActionsKeys = keyof typeof entitiesActions
type EntitiesActions = (typeof entitiesActions)[EntitiesActionsKeys]

type EntitiesReducerAction = {
  type: EntitiesActions
  entity?: Entity
  entities?: Array<Entity>
}

const reducer = (
  state: EntitiesReducerState,
  action: EntitiesReducerAction,
): EntitiesReducerState => {
  switch (action.type) {
    case entitiesActions.SET_ALL_PARENT_ENTITIES:
      return { ...state, allEntities: action.entities as [Entity] }

    case entitiesActions.SET_CHILDREN_ENTITES:
      return { ...state, childrenEntities: action.entities as [Entity] }

    case entitiesActions.SET_SELECTED_PARENT:
      return { ...state, selectedParent: action.entity as Entity }

    case entitiesActions.SET_SELECTED_CHILD:
      return { ...state, selectedChild: action.entity as Entity }
    case entitiesActions.SET_FILTERING_ENTITY:
      return { ...state, filteringEntity: action.entity as Entity }

    case entitiesActions.REMOVE_SELECTED_PARENT:
      return { ...state, selectedParent: defaultEntity }

    case entitiesActions.REMOVE_SELECTED_CHILD:
      return { ...state, selectedChild: defaultEntity }

    case entitiesActions.REMOVE_FILTERING_ENTITY:
      return { ...state, filteringEntity: defaultEntity }

    default:
      return state
  }
}

type EntitiesContextProps = {
  allEntities: EntitiesReducerState['allEntities']
  selectedParent: EntitiesReducerState['selectedParent']
  childrenEntities: EntitiesReducerState['childrenEntities']
  selectedChild: EntitiesReducerState['selectedChild']
  filteringEntity: EntitiesReducerState['filteringEntity']

  setAllEntities: (entities: Array<Entity>) => void
  setSelectedParent: (entity: Entity) => void
  setChildrenEntities: (entities: Array<Entity>) => void
  setSelectedChild: (entity: Entity) => void
  setFilteringEntity: (entity: Entity) => void

  removeSelectedParent: () => void
  removeSelectedChild: () => void
  removeSelectedFilteringEntity: () => void

  isLoading: boolean
  isError: boolean
}

const defaultEntitiesContext: EntitiesContextProps = {
  allEntities: [],
  childrenEntities: [],
  selectedParent: defaultEntity,
  selectedChild: defaultEntity,
  filteringEntity: defaultEntity,
  setAllEntities: (_entities) => {},
  setSelectedParent: (_entity) => {},
  setChildrenEntities: (_entities) => {},
  setSelectedChild: (_entity) => {},
  setFilteringEntity: (_entity) => {},
  removeSelectedParent: () => {},
  removeSelectedChild: () => {},
  removeSelectedFilteringEntity: () => {},
  isLoading: false,
  isError: false,
}

const EntitiesContext = React.createContext<EntitiesContextProps>(defaultEntitiesContext)

const EntitiesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, defaultEntitiesReducerState)

  const { allEntities, childrenEntities, selectedChild, selectedParent, filteringEntity } = state

  const setAllEntities = (entities: Array<Entity>) => {
    sortEntities(entities)
    dispatch({ type: entitiesActions.SET_ALL_PARENT_ENTITIES, entities })
  }

  const setSelectedParent = (entity: Entity) => {
    dispatch({ type: entitiesActions.SET_SELECTED_PARENT, entity })
  }

  const setChildrenEntities = (entities: Array<Entity>) => {
    sortEntities(entities)
    dispatch({ type: entitiesActions.SET_CHILDREN_ENTITES, entities })
  }

  const setSelectedChild = (entity: Entity) => {
    dispatch({ type: entitiesActions.SET_SELECTED_CHILD, entity })
  }

  const setFilteringEntity = (entity: Entity) => {
    dispatch({ type: entitiesActions.SET_FILTERING_ENTITY, entity })
  }

  const removeSelectedParent = () => {
    dispatch({ type: entitiesActions.REMOVE_SELECTED_PARENT })
  }

  const removeSelectedChild = () => {
    dispatch({ type: entitiesActions.REMOVE_SELECTED_CHILD })
  }

  const removeSelectedFilteringEntity = () => {
    dispatch({ type: entitiesActions.REMOVE_FILTERING_ENTITY })
  }

  const { data: fetchedEntities, isLoading, isError } = useGetAllEntities()

  React.useEffect(() => {
    if (fetchedEntities) {
      setAllEntities(fetchedEntities)
    }
  }, [fetchedEntities])

  const providerValue = React.useMemo<EntitiesContextProps>(
    () => ({
      allEntities,
      selectedParent,
      childrenEntities,
      selectedChild,
      filteringEntity,
      setAllEntities,
      setSelectedParent,
      setChildrenEntities,
      setSelectedChild,
      setFilteringEntity,
      removeSelectedParent,
      removeSelectedChild,
      removeSelectedFilteringEntity,
      isLoading,
      isError,
    }),
    [
      allEntities,
      selectedParent,
      childrenEntities,
      selectedChild,
      filteringEntity,
      isLoading,
      isError,
    ],
  )
  return <EntitiesContext.Provider value={providerValue}>{children}</EntitiesContext.Provider>
}

const useEntities = () => React.useContext(EntitiesContext)

export { EntitiesProvider, useEntities }
