import React from 'react'
import { AuthenticatedUser, defaultAuthenticatedUser } from 'models'
import axios from 'axios'
import { PATHS } from 'utils/constants'

const AuthenticatedUserContext = React.createContext({
  authenticatedUser: defaultAuthenticatedUser,
  setAuthenticatedUser: ((_authenticatedUser: AuthenticatedUser) => {}) as React.Dispatch<
    React.SetStateAction<AuthenticatedUser>
  >,
  isLoading: true,
  isError: null,
  isLoginB2B: true,
  isLoginB2C: false,
})

const AuthenticatedUserProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [authenticatedUser, setAuthenticatedUser] = React.useState(defaultAuthenticatedUser)
  const [isLoading, setLoading] = React.useState(true)
  const [isError, setError] = React.useState(null)
  const [isLoginB2B, setIsLoginB2B] = React.useState<boolean>(true)
  const [isLoginB2C, setIsLoginB2C] = React.useState<boolean>(false)

  React.useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(PATHS.userInfo)
        setAuthenticatedUser(response.data)
        setIsLoginB2B(response.data.clientPrincipal?.identityProvider === 'aad')
        setIsLoginB2C(response.data.clientPrincipal?.identityProvider !== 'aad')
      } catch (err) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setError(err as any)
      } finally {
        setLoading(false)
      }
    }

    fetchUserInfo()
  }, [])

  const providerValue = React.useMemo(
    () => ({
      isError,
      isLoading,
      authenticatedUser,
      isLoginB2B,
      isLoginB2C,
      setAuthenticatedUser,
    }),
    [authenticatedUser, isError, isLoading, isLoginB2B, isLoginB2C],
  )

  return (
    <AuthenticatedUserContext.Provider value={providerValue}>
      {children}
    </AuthenticatedUserContext.Provider>
  )
}

const useAuthenticatedUser = () => React.useContext(AuthenticatedUserContext)

export { AuthenticatedUserContext, AuthenticatedUserProvider, useAuthenticatedUser }
