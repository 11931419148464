import React from 'react'
import { GroupsOutlined, Person } from '@mui/icons-material'
import { ComboBox } from 'components/common'
import { useAuthenticatedUser, useEntities, useWaitingFilesCount } from 'contexts'
import { Entity, defaultEntity } from 'models/erp'

import { AutocompleteChangeReason } from '@mui/material'
import { theme } from 'utils/theme'
import { useGetContact } from 'utils/hooks/queries/useGetContact'
import { entityDisplayName, isEntityDefault } from 'utils/helpers'
import { useGetChildren } from 'utils/hooks/queries'
import { allAccounts } from 'utils/constants'
import { NavBarSellerFamily } from './NavBarSellerFamily'

const NavBarSellerSection = () => {
  const { authenticatedUser } = useAuthenticatedUser()
  const isLoginB2B = authenticatedUser.clientPrincipal?.identityProvider === 'aad'
  // const isLoginB2B = false
  const isLoginB2C = !isLoginB2B
  const { setWaitingFilesCount } = useWaitingFilesCount()

  const {
    selectedParent,
    selectedChild,
    childrenEntities,
    allEntities,
    setFilteringEntity,
    setSelectedParent,
    setChildrenEntities,
    setSelectedChild,
    removeSelectedChild,
    removeSelectedFilteringEntity,
  } = useEntities()

  const [childInput, setChildInput] = React.useState<Entity | null>(null)

  const isAllAccountsSelected = entityDisplayName(selectedChild) === allAccounts
  const noParent = isEntityDefault(selectedParent)
  const noChildSelected = isEntityDefault(selectedChild) || isAllAccountsSelected

  const { data: contact } = useGetContact()

  const handleBtoC = () => {
    if (isLoginB2B || !authenticatedUser || !allEntities) {
      return
    }

    const userEntity = allEntities.find((ent: Entity) => ent.id === contact?.assignedEntityId)

    if (!userEntity) {
      return
    }
    const hasChildren = userEntity.childrenCount && userEntity.childrenCount !== 0
    const hasParent = userEntity.parentId !== ''

    if (hasChildren || !hasParent) {
      setFilteringEntity(userEntity)
      setSelectedParent(userEntity)
      return
    }

    const parentEntity: Entity = allEntities.filter(
      (ent: Entity) => ent.id === userEntity.parentId,
    )[0]

    setSelectedParent(parentEntity)
    setFilteringEntity(parentEntity)
  }

  React.useEffect(() => {
    if (isLoginB2C) {
      handleBtoC()
    }
  }, [authenticatedUser, allEntities])

  const { data: fetchedChildren } = useGetChildren()
  React.useEffect(() => {
    if (fetchedChildren) {
      setChildrenEntities(fetchedChildren)
    }
  }, [fetchedChildren])

  React.useEffect(() => {
    removeSelectedChild()
    setChildInput(null)
  }, [selectedParent])

  React.useEffect(() => {
    if (!isEntityDefault(selectedChild)) {
      setChildInput(selectedChild)
    }
  }, [childInput, selectedChild])

  const handleEntitySelection = (
    event: React.SyntheticEvent<Element, Event>,
    option: Entity | null | undefined,
    reason: AutocompleteChangeReason,
  ) => {
    removeSelectedFilteringEntity()
    removeSelectedChild()
    setWaitingFilesCount(0)

    if (reason === 'clear') {
      return
    }
    setSelectedChild(option as Entity)
  }

  React.useEffect(() => {
    if (noParent) {
      return
    }
    if (noChildSelected) {
      setFilteringEntity(selectedParent)
      setSelectedChild(defaultEntity)
      return
    }
    setFilteringEntity(selectedChild)
  }, [selectedParent, selectedChild])

  // Add all sellers option

  const addAllAccountsOption = (() => {
    const allChildrenEntityLabel: Entity = {
      ...defaultEntity,
      id: selectedParent.id,
      name: allAccounts,
    }
    if (childrenEntities.length === 0) {
      return [...childrenEntities]
    }
    return [allChildrenEntityLabel, ...childrenEntities]
  })()

  return (
    <>
      <GroupsOutlined className="ml-3" />
      {isLoginB2B ? (
        <NavBarSellerFamily />
      ) : (
        <p className="ml-2">{entityDisplayName(selectedParent)}</p>
      )}

      {childrenEntities.length > 0 ? (
        <>
          <Person className="ml-3" />
          <ComboBox
            options={addAllAccountsOption}
            getOptionLabel={(option: Entity) => entityDisplayName(option)}
            getOptionKey={(option: Entity) => option.id}
            selectedOption={childInput}
            onSelection={handleEntitySelection}
            boxStyle={
              selectedChild ? undefined : { border: `3px solid ${theme.palette.primary.main}` }
            }
          />
        </>
      ) : null}
    </>
  )
}

export default NavBarSellerSection
