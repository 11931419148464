type Status = {
  administrativeStatus: number | null | string
  logisticStatus: number | null | string
  generalStatus: number | null | string
  auctionStatus: number | null | string
  registrationDocumentStatus: number | null | string
  qualityControlStatus: number | null | string
  blockingReason: string | null | string
}

const defaultStatus: Status = {
  administrativeStatus: '',
  logisticStatus: '',
  generalStatus: '',
  auctionStatus: '',
  registrationDocumentStatus: '',
  qualityControlStatus: '',
  blockingReason: '',
}

const loadingStatus: Status = {
  administrativeStatus: '...',
  logisticStatus: '...',
  generalStatus: '...',
  auctionStatus: '...',
  registrationDocumentStatus: '...',
  qualityControlStatus: '...',
  blockingReason: '...',
}

const errorStatus: Status = {
  administrativeStatus: 'Err',
  logisticStatus: 'Err',
  generalStatus: 'Err',
  auctionStatus: 'Err',
  registrationDocumentStatus: 'Err',
  qualityControlStatus: 'Err',
  blockingReason: 'Err',
}

export type { Status }
export { defaultStatus, loadingStatus, errorStatus }
