const normaliseImmat = (entryImmat: string) => {
  return entryImmat
    .trim() // Remove leading and trailing whitespace
    .toUpperCase() // Convert to uppercase
    .replace(/\s+/g, '-') // Replace all spaces with a dash
    .replace(/([A-Z]+)(?=\d)|(\d+)(?=[A-Z])/g, '$&-') // Add dashes between letters and numbers
    .replace(/-+/g, '-') // Ensure no consecutive dashes
}

export { normaliseImmat }
