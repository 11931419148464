import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PATHS } from 'utils/constants'
import { WaitingFilesCountProvider } from './WaitingFilesCountContext'
import { FutureSaleProvider } from './FutureSaleContext'
import { SaleResultProvider } from './SaleResultContext'
import { useAuthenticatedUser } from './UserContext'

const PrivateRouteProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const navigate = useNavigate()
  const { authenticatedUser, isLoading } = useAuthenticatedUser()
  React.useEffect(() => {
    if (authenticatedUser && authenticatedUser.clientPrincipal === null && !isLoading) {
      navigate(PATHS.signIn)
    }
  }, [authenticatedUser])
  return (
    <WaitingFilesCountProvider>
      <FutureSaleProvider>
        <SaleResultProvider>{children}</SaleResultProvider>
      </FutureSaleProvider>
    </WaitingFilesCountProvider>
  )
}

export default PrivateRouteProvider
