import React from 'react'
import { Navigate, RouteObject } from 'react-router-dom'
import {
  FutureSalesPage,
  FutureSaleVehiclesPage,
  SaleResultDetailPage,
  SalesResultPage,
  SearchPage,
  SignInPage,
  StockPage,
  WaitingFilesPage,
} from 'pages'
import { PATHS } from 'utils/constants'
import AuthorizationCallbackPage from 'pages/authorization/callback'
import { PrivateRoute, UnauthenticatedRoute } from 'components/routes'
import { SideNavBarLayout } from 'layouts'

const routes: RouteObject[] = [
  {
    element: <PrivateRoute />,
    children: [
      {
        path: PATHS.home,
        element: <SideNavBarLayout />,
        children: [
          {
            index: true,
            element: (
              <Navigate
                replace
                to={PATHS.futureSales}
              />
            ),
          },
          {
            path: PATHS.futureSales,
            element: <FutureSalesPage />,
          },
          {
            path: PATHS.futureSalesDetails,
            element: <FutureSaleVehiclesPage />,
          },
          {
            path: PATHS.salesResult,
            element: <SalesResultPage />,
          },
          {
            path: PATHS.salesResultDetails,
            element: <SaleResultDetailPage />,
          },
          {
            path: PATHS.stock,
            element: <StockPage />,
          },
          {
            path: PATHS.waitingFiles,
            element: <WaitingFilesPage />,
          },
          {
            path: PATHS.search,
            element: <SearchPage />,
          },
        ],
      },
    ],
  },
  {
    element: <UnauthenticatedRoute />,
    children: [
      {
        path: PATHS.signIn,
        element: <SignInPage />,
      },
      {
        path: PATHS.authorizationCallback,
        element: <AuthorizationCallbackPage />,
      },
    ],
  },
]

export { routes }
