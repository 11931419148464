import { useQuery } from '@tanstack/react-query'
import { useAlert, useTranslator } from 'contexts'
import { defaultVehicle, Vehicle } from 'models/erp'
import React from 'react'

import { currentQueryService } from 'utils/api/queries'
import { defaultUniversalVehicle, extractUniversalVehicle } from 'utils/helpers/extractViewModels'

const useGetVehicleByNo = (no: Vehicle['no']) => {
  const { translateStatusVehicle } = useTranslator()
  const { displayAlert } = useAlert()

  const result = useQuery<Vehicle, Error>({
    queryKey: ['useGetVehicleByNo', no],
    queryFn: () => currentQueryService.getVehicleByNo(no),
    retry: false,
  })
  //   return query

  const { isLoading, isError, error, refetch } = result

  const vehicleData = isError
    ? defaultUniversalVehicle
    : extractUniversalVehicle(translateStatusVehicle(result.data as Vehicle))

  React.useEffect(() => {
    if (isError) {
      displayAlert(error.message, 'error')
    }
  }, [isError])

  return {
    isLoading,
    isError,
    refetch,
    error,
    vehicleData,
  }
}

export { useGetVehicleByNo }
