import { defaultStatus, Status } from './status'
import { defaultVehicleAuction, VehicleAuction } from './vehicleAuction'
import { defaultVehicleDetail, VehicleDetail } from './vehicleDetail'
import { VehicleDocument } from './vehicleDocument'
import { defaultVehicleInformation, VehicleInformation } from './vehicleInformation'
import { VehicleLocation } from './vehicleLocation'
import { defaultVehiclePartial, VehiclePartial } from './vehiclePartial'
import { defaultVehiclePricing, VehiclePricing } from './vehiclePricing'
import { VehicleVendorInformation } from './vehicleVendorInformation'

type Vehicle = {
  no: string
  entityId: string
  contactId: string
  vehiclePartial: VehiclePartial
  vehicleDetail: VehicleDetail
  pickupLocation: null | string
  location: VehicleLocation | null
  vendorInformation: VehicleVendorInformation | null
  information: VehicleInformation
  pricing: VehiclePricing
  auction: null | VehicleAuction
  prestations: []
  status: Status
  documents: Array<VehicleDocument> | null
  photos: [
    {
      fileStorageId: string
      filename: string
      doctype: null | unknown
      attributes: null | unknown
      url: null | unknown
    },
  ]
}
const defaultVehicle: Vehicle = {
  no: '',
  entityId: '',
  contactId: '',
  vehiclePartial: defaultVehiclePartial,
  vehicleDetail: defaultVehicleDetail,
  pickupLocation: null,
  location: null,

  vendorInformation: {
    providedMileage: null,
    providedFRE: '',
    providedEstimation: '',
    priceReserve: '',
    priceReserveExclVat: '',
    providedVehicleDescription: null,
    referenceVendeur: null,
  },
  information: defaultVehicleInformation,
  pricing: defaultVehiclePricing,
  auction: defaultVehicleAuction,

  prestations: [],
  status: defaultStatus,
  documents: [],
  photos: [
    {
      fileStorageId: '',
      filename: '',
      doctype: null,
      attributes: null,
      url: null,
    },
  ],
}

export type { Vehicle }
export { defaultVehicle }
