import React from 'react'
import { DataTable } from 'components/dataTable'
import { useParams } from 'react-router-dom'
import { useGetAuctionVehicles } from 'utils/hooks/queries'
import { useAlert } from 'contexts'
import { useLoading } from 'utils/hooks'
import { generateAuctionsTableTitle } from 'utils/helpers/generateAuctionsTable'

import useVehicleModal from 'utils/hooks/useVehicleModal'
import { futureAuctionVehiclesTableLabels } from 'utils/labels'

const FutureSaleVehiclesPage = () => {
  const { Loading } = useLoading()
  const { displayAlert } = useAlert()
  const { setOpenModal, VehicleModal } = useVehicleModal()
  const { auctionNo } = useParams()
  const searchStr: string = auctionNo !== undefined ? auctionNo : ''
  const {
    data: futureAuctionVehicles,
    auction: futureAuction,
    isLoading,
    error: getError,
  } = useGetAuctionVehicles(searchStr)

  const isDataEmpty = futureAuction === undefined
  const [vehicleModalActiveId, setVehicleModalActiveId] = React.useState<string>('')

  React.useEffect(() => {
    if (getError?.message) {
      displayAlert(`${getError.message}`, 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getError])

  if (isLoading) return <Loading />
  if (isDataEmpty || getError) {
    return (
      <p>
        Les données ne peuvent pas être affichées, veuillez contacter{' '}
        <a
          href="mailto:support@vpauto.zendesk.com"
          className="underline underline-offset-4"
        >
          support@vpauto.zendesk.com
        </a>
      </p>
    )
  }

  return (
    // <FutureSaleVehiclesContext.Provider value={formattedFutureSaleVehicles}>
    <>
      <DataTable
        tableTitle={generateAuctionsTableTitle(futureAuction)}
        columnsTitles={futureAuctionVehiclesTableLabels}
        data={futureAuctionVehicles}
        options={{
          onRowClick: (line) => {
            console.log(line)
            // const typedLine = line as unknown as VehicleAuction
            // setVehicleModalActiveId(typedLine.project)
            // setOpenModal(true)
          },
          enableUpload: true,
        }}
      />
      <VehicleModal
        id={vehicleModalActiveId}
        onClose={() => setVehicleModalActiveId('')}
      />
    </>
    // </FutureSaleVehiclesContext.Provider>
  )
}

export default FutureSaleVehiclesPage
