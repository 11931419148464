import React from 'react'
import { useAlert, useEntities, useTranslator } from 'contexts'
import { useLoading } from 'utils/hooks'
import { ImmatSearchInput } from 'components'
import { normaliseImmat } from 'utils/helpers/format'
import useVehicleModal from 'utils/hooks/useVehicleModal'
import { useGetVehicles } from 'utils/hooks/queries'
import { compareObjectsDifferent } from 'utils/helpers'
import { defaultVehicle } from 'models/erp'
import { DataTable } from 'components/dataTable'
import { searchResultTableLabels } from 'utils/labels'
import { VehicleUniversalData } from 'models/universalData'

const SearchPage = () => {
  // TODO: Solve delay when changing entity!
  const { displayAlert } = useAlert()
  const { Loading } = useLoading()
  const { setOpenModal, VehicleModal } = useVehicleModal()

  const [immatToSearch, setImmatToSearch] = React.useState<string>('')
  const [vehicleModalActiveId, setVehicleModalActiveId] = React.useState<string>('')

  const { filteringEntity } = useEntities()

  // const {

  const { vehiclesUniversalData, isGetVehicleLoading } = useGetVehicles(
    'licensePlate',
    immatToSearch,
  )

  const hasVehicleData = vehiclesUniversalData && vehiclesUniversalData?.length !== 0

  React.useEffect(() => {
    console.log('vehiclesUniversalData', vehiclesUniversalData)
    console.log('hasVehicleData', hasVehicleData)
  }, [vehiclesUniversalData])

  const handleSearch = (immat: string) => {
    console.log('norm', normaliseImmat(immat))
    setImmatToSearch(normaliseImmat(immat))
    // setImmatToSearch(immat)
  }

  // React.useEffect(() => {
  //   if (immatToSearch) {
  //     refetchVehicles()
  //   }
  //   if (filteringEntity) {
  //     refetchVehicles()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [immatToSearch, filteringEntity])

  if (isGetVehicleLoading) {
    return (
      <>
        <ImmatSearchInput onSearch={handleSearch} />
        <Loading />
      </>
    )
  }

  if (immatToSearch.length === 0 && !isGetVehicleLoading) {
    return <ImmatSearchInput onSearch={handleSearch} />
  }

  return (
    <>
      <ImmatSearchInput onSearch={handleSearch} />

      {hasVehicleData ? (
        <DataTable
          tableTitle={`Résultats de recherche pour "${immatToSearch}"`}
          columnsTitles={searchResultTableLabels}
          data={vehiclesUniversalData as Array<VehicleUniversalData>}
          options={{
            onRowClick: (line) => {
              console.log(line)
              // const typedLine = line as unknown as VehicleSheetDTO
              // setVehicleModalActiveId(typedLine.project)
              // setOpenModal(true)
            },
            disableDownload: true,
          }}
        />
      ) : (
        //   <VehicleModal
        //     id={vehicleModalActiveId}
        //     onClose={() => setVehicleModalActiveId('')}
        //   />
        // </>

        <p>
          Pas de résultat à afficher pour &ldquo;
          <span className="font-bold">{immatToSearch}&ldquo;</span>
        </p>
      )}
    </>
  )
}

export default SearchPage
