const endpointApiPrefix = '/v1'

const ENDPOINTS = {
  TRANSLATOR_OBJECT: '/enums',
  AUCTIONS_LIGHT: '/auctionsLight',
  FUTURE_SALES: `${endpointApiPrefix}/dateventefutures`,
  FUTURE_SALES_BY_FAMILY: `${endpointApiPrefix}/dateventefuturesfamille`,
  FUTURE_SALE_VEHICLES: `${endpointApiPrefix}/dateventefuturesliste`,
  SALES_RESULT: `${endpointApiPrefix}/dateventepassees`,
  SALES_RESULT_BY_FAMILY: `${endpointApiPrefix}/dateventepasseesfamille`,
  SALE_RESULT_VEHICLES: `${endpointApiPrefix}/dateventepasseesliste`,
  STOCK: `${endpointApiPrefix}/stock`,
  VEHICLE: `${endpointApiPrefix}/vehicule`,
  SELLERS: `${endpointApiPrefix}/vendeur`,
  CURRENT_SELLER: `${endpointApiPrefix}/vendeur/me`,
  ALL_FAMILIES: `${endpointApiPrefix}/cardcodefamily`,
  MOCK_UPDATE_VEHICLE_RESERVE:
    'https://d63226e1-4e38-4e37-adf6-5ce747ed0462.mock.pstmn.io/testMockHanaUpdateVh',
  MOCK_UPDATE_VEHICLE_RESERVE_ERROR:
    'https://d63226e1-4e38-4e37-adf6-5ce747ed0462.mock.pstmn.io/testMockHanaUpdateVhKO',
  VEHICLE_PHOTOS: 'https://apim-datahub-prod.azure-api.net/proxy/cdnvpauto',

  FILE: '/file',
} as const

type EndpointKeys = keyof typeof ENDPOINTS
type Endpoints = (typeof ENDPOINTS)[EndpointKeys]

export { ENDPOINTS }
export type { EndpointKeys, Endpoints }
