/* eslint-disable @typescript-eslint/no-unused-vars */
import { dummyPhotoResponse, dummyVehicleSheetsDTOArray } from 'tests/dummyData'

import axios from 'axios'

import { ENDPOINTS, Endpoints } from 'utils/constants'

import { isDateFuture } from 'utils/helpers'
import {
  defaultContact,
  Contact,
  AuctionDTO,
  Vehicle,
  Entity,
  AuctionLightDTO,
  Translator,
  defaultAuctionLightDTO,
  defaultVehicle,
  VehicleDocument,
} from 'models/erp'

import { DateISO8601 } from 'models'
import { defaultUniversalVehicle, extractUniversalVehicles } from 'utils/helpers/extractViewModels'

import { ApiPhotosResponse, IQueries, UpdateVehicleReserveAxiosResponse } from './queryInterface'
import axiosInstance from '../../../api/axiosInstance/axiosInstance'
import { generateTimeLineFilter, OdataFilterBuilder } from '../filters'

const dummyQueryAdapter: IQueries = {
  getContact: async (contactId: string) => {
    if (!contactId) {
      return defaultContact
    }
    const queryParams = {
      params: {
        id: contactId,
      },
    }
    try {
      const response = await axiosInstance.get<Contact>(`/contact`, { ...queryParams })
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }
      return response.data
    } catch (error) {
      // Handle network or other Axios errors
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  getAuction: async (auctionNo: string) => {
    try {
      const queryParams = {
        params: {
          auction: auctionNo,
        },
      }
      const response = await axiosInstance.get<AuctionDTO>('/auction', {
        ...queryParams,
      })
      // Explicitly handle HTTP errors by throwing an exception
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }

      return response.data // Return the auction data if the request is successful
      // Handle network or other Axios errors
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  getAuctionsLight: async (filteringEntity: Entity, timeLine) => {
    const queryFilter = new OdataFilterBuilder()
      .entityIdFilter(filteringEntity.id)
      .and()
      .timeLineFilter(timeLine)
      .build()

    const filterParams = {
      params: {
        filter: queryFilter,
        limit: 1000000,
        page: 0,
      },
    }
    try {
      const response = await axiosInstance.get<Array<AuctionLightDTO>>(
        ENDPOINTS.AUCTIONS_LIGHT,
        filterParams,
      )
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }
      return response.data
    } catch (error) {
      // Handle network or other Axios errors
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  getStockFromEntity: async (filteringEntity: Entity): Promise<Vehicle[]> => {
    const statusFilter = `status/generalStatus eq 1`
    const queryFilter = new OdataFilterBuilder()
      .entityIdFilter(filteringEntity.id)
      .and(statusFilter)
      .build()
    const filterParams = {
      params: {
        filter: queryFilter,
        limit: 1000000,
        page: 0,
      },
    }
    // Make the API request
    try {
      const response = await axiosInstance.get<Array<Vehicle>>('/vehicles', filterParams)
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  getWaitingFilesFromEntity: async (filteringEntity: Entity): Promise<Vehicle[]> => {
    const statusFilter = `status/generalStatus eq 7`
    const queryFilter = new OdataFilterBuilder()
      .entityIdFilter(filteringEntity.id)
      .and(statusFilter)
      .build()
    const filterParams = {
      params: {
        filter: queryFilter,
        limit: 1000000,
        page: 0,
      },
    }
    // Make the API request
    try {
      const response = await axiosInstance.get<Array<Vehicle>>('/vehicles', filterParams)

      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }
      return response.data
    } catch (error) {
      // Handle network or other Axios errors
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  getAllEntities: async (): Promise<Array<Entity>> => {
    try {
      const response = await axiosInstance.get<Array<Entity>>('/entities')
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }
      return response.data
    } catch (error) {
      // Handle network or other Axios errors
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  getChildren: async (parentEntity: Entity) => {
    const parentIdFilter = `parentId eq '${parentEntity.id}'`
    const queryFilter = new OdataFilterBuilder().add(parentIdFilter).build()
    const filterParams = {
      params: {
        filter: queryFilter,
        limit: 1000000,
        page: 0,
      },
    }
    try {
      const response = await axiosInstance.get<Array<Entity>>('/entities', {
        ...filterParams,
      })
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }

      return response.data
    } catch (error) {
      // Handle network or other Axios errors
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  // TODO: Readapt once eq filters on strings are OK
  getVehicles: async (
    filteringEntity: Entity,
    key: 'no' | 'licensePlate' | 'vin',
    value: string,
  ) => {
    // TODO: manage NO
    if (key === 'no') {
      return [defaultVehicle]
    }
    const filterVehicle: Record<typeof key, string> = {
      // no: `contains(no, '${value}')`,
      licensePlate: `contains(vehiclePartial/licensePlate, '${value}')`,
      vin: `contains(vehiclePartial/vin, '${value}')`,
    }

    const queryFilter = new OdataFilterBuilder()
      .entityIdFilter(filteringEntity.id)
      .and(filterVehicle[key])
      .build()

    const filterParams = {
      params: {
        filter: queryFilter,
        limit: 10,
        page: 0,
      },
    }
    try {
      const response = await axiosInstance.get<Array<Vehicle>>('/vehicles', filterParams)
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }

      return response.data
    } catch (error) {
      // Handle network or other Axios errors
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  getVehicleByNo: async (no) => {
    if (!no) {
      return defaultVehicle
    }
    try {
      const queryParams = {
        params: {
          vehicleNo: no,
        },
      }
      const response = await axiosInstance.get<Vehicle>('/vehicleByNo', {
        ...queryParams,
      })
      // Explicitly handle HTTP errors by throwing an exception
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }

      return response.data // Return the auction data if the request is successful
      // Handle network or other Axios errors
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }

    // return defaultVehicle
  },

  getStatusesObject: async () => {
    const queryParams = {
      params: {
        mode: 'object',
      },
    }
    try {
      const response = await axiosInstance.get<Translator>(ENDPOINTS.TRANSLATOR_OBJECT, queryParams)
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }
      return response.data
    } catch (error) {
      // Handle network or other Axios errors
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  // getFileStorageUrl: async (fileStorageId: string) => {
  //   const queryParams = {
  //     params: {
  //       fileId: fileStorageId,
  //     },
  //   }
  //   if (fileStorageId) {
  //     const { data: fileUrl } = await axiosInstance.get(ENDPOINTS.FILE, queryParams)

  //     return fileUrl
  //   }
  //   return ''
  // },

  // old stuff

  getVehicleByImmat: async (_vehicleImmat) => {
    const vehicles = dummyVehicleSheetsDTOArray.filter(
      (currentVehicle) => currentVehicle.immatriculation === _vehicleImmat,
    )

    return vehicles
  },

  updateVehicleReserve: async ({ vehicleProject, newReserve }) => {
    const randomRoute =
      // 0 for error, 1 for success
      Math.random() < 0.5
        ? ENDPOINTS.MOCK_UPDATE_VEHICLE_RESERVE
        : ENDPOINTS.MOCK_UPDATE_VEHICLE_RESERVE_ERROR

    const response = await axiosInstance.put<
      Endpoints,
      UpdateVehicleReserveAxiosResponse,
      {
        numDossier: string
        reserve: string
      }
    >(randomRoute, {
      numDossier: vehicleProject,
      reserve: newReserve.toString(),
    })
    return response
  },

  getPhotos: async (immat: string) => {
    const { data: photos } = await axios.post<ApiPhotosResponse>(
      ENDPOINTS.VEHICLE_PHOTOS,
      {
        immat,
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )

    if (!photos.valid) {
      return dummyPhotoResponse
    }
    return photos
  },
}

export { dummyQueryAdapter }
