import { DateISO8601 } from 'models/isoDate'
import { AuctionDTO } from './auction'
import { AuctionRoom } from './auctionRoom'

type VehicleAuction = {
  no: string
  passingOrder: number
  passingIndex: null | unknown
  description: string
  auctionStartDateTime: DateISO8601 | '' | null
  auctionEndTime: null | unknown
  auctionRoom: AuctionRoom
  isInternetAuction: boolean
  auctionType: number
  subAuction: null | unknown
  auctionTemplate: string
  numberOfLotInAuction: number
  numberOfLotSold: number
  numberOfLotRetired: number
  numberOfLotWithoutReserve: number
  adjudicatedAmount: string
  retiredAmount: string
}

const defaultVehicleAuction: VehicleAuction = {
  no: '',
  passingOrder: 0,
  passingIndex: null,
  description: '',
  auctionStartDateTime: null,
  auctionEndTime: null,
  auctionRoom: null,
  isInternetAuction: false,
  auctionType: 0,
  subAuction: null,
  auctionTemplate: '',
  numberOfLotInAuction: 0,
  numberOfLotSold: 0,
  numberOfLotRetired: 0,
  numberOfLotWithoutReserve: 0,
  adjudicatedAmount: '',
  retiredAmount: '',
}

export type { VehicleAuction }
export { defaultVehicleAuction }
