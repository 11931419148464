import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useEntities } from 'contexts'
import { Entity } from 'models/erp'
import { currentQueryService } from 'utils/api/queries'
import { isEntityDefault } from 'utils/helpers'

const useGetChildren = () => {
  const { selectedParent } = useEntities()
  const query = useQuery<Array<Entity>, Error>({
    queryKey: ['useGetChildren'],
    queryFn: () => currentQueryService.getChildren(selectedParent),
    // enabled: isEntityNotDefault(selectedParent),
    enabled: false,
  })
  const { refetch } = query

  React.useEffect(() => {
    if (isEntityDefault(selectedParent)) return
    refetch()
  }, [selectedParent])

  return query
}

export { useGetChildren }
