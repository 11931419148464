type StatusValue = {
  title: string
  value: string
}

type StatusEnum = {
  name: string
  label: string
  values: Array<StatusValue>
}

type Translator = {
  mode: 'array'
  enums: Array<StatusEnum>
}

// type TranslatorKeys = Exclude<keyof Translator, 'mode'>

const dummyTranslator: Translator = {
  mode: 'array',
  enums: [
    {
      name: '',
      label: '',
      values: [
        {
          title: '',
          value: '',
        },
      ],
    },
  ],
}

export type {
  Translator,
  StatusEnum,
  // TranslatorKeys,
  // StatusValues,
}
export { dummyTranslator }
