import { useQuery } from '@tanstack/react-query'
import { Translator } from 'models/erp'

import { currentQueryService } from 'utils/api/queries'

const useGetStatusesTranslator = () => {
  const query = useQuery<Translator, Error>({
    queryKey: ['useGetStatusesTranslator'],
    queryFn: () => currentQueryService.getStatusesObject(),
  })
  return query
}

export { useGetStatusesTranslator }
