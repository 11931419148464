import { Equipment } from './equipment'

type VehiclePartial = {
  licensePlate: string
  vin: string
  dateProduction: null | string
  dateDelivery: null | string
  dateFirstReg: string
  dateLastReg: null | string
  color: string
  typeRegDoc: null | unknown
  fuelType: string
  type: null | unknown
  bodyType: string
  bodyTypeRegDoc: string
  fuelTypeRegDoc: string
  euroNorm: null | unknown
  euroNormRegDoc: null | unknown
  curbWeight: null | unknown
  totalWeight: null | unknown
  typeCode: null | unknown
  deliveryCountry: null | unknown
  critair: null | unknown
  numberOfSeats: null | unknown
  numberOfDoors: null | unknown
  numberOfCylinders: null | unknown
  equipments: Array<Equipment>
  equipmentsDTO: Array<Equipment>
}

const defaultVehiclePartial: VehiclePartial = {
  licensePlate: '',
  vin: '',
  dateProduction: null,
  dateDelivery: '',
  dateFirstReg: '',
  dateLastReg: null,
  color: '',
  typeRegDoc: null,
  type: '',
  bodyType: '',
  bodyTypeRegDoc: '',
  fuelType: '',
  fuelTypeRegDoc: '',
  euroNorm: '',
  euroNormRegDoc: '',
  curbWeight: '',
  totalWeight: '',
  typeCode: null,
  deliveryCountry: null,
  critair: '',
  numberOfSeats: 0,
  numberOfDoors: 0,
  numberOfCylinders: 0,
  equipments: [],
  equipmentsDTO: [],
}

export type { VehiclePartial }
export { defaultVehiclePartial }
