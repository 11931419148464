import { DateISO8601 } from 'models'

const isDateFuture = (dateToCheck: string | DateISO8601) => {
  // Convert the string date to a Date object
  const dateToCheckObj = new Date(dateToCheck)

  // Get today's date
  const currentDate = new Date()

  const isFuture = dateToCheckObj > currentDate
  // Check if the date is in the future
  return isFuture
}

export { isDateFuture }
