/* eslint-disable max-len */
import { VehicleSheetDTO } from 'models'

const dummyVehicleSheetDTO1: VehicleSheetDTO = {
  metadata: {
    type: 'oiu',
    uri: 'kjh',
  },
  project: 'AAAAA',
  dossierRefVendeur: 'string',
  dateSortie: '2023-10-19T08:33:56.460Z',
  prixAdjudication: 10203,
  coteStandard: 205,
  cotePerso: 207,
  coteKm: 107,
  nbRetrait: 12,
  nbRetraitE: 12,
  estimation: 12003,
  reserve: 15678,
  kilomReel: 12,
  kilomDeclare: 12,
  observationsVendeur:
    'belle/ Badge Pro/ Pavillon noir/1 cable T2-T2+1 T2- domestique 220V/ Volant chauffant/Selecteur modes conduites/Rays.',
  immatriculation: 'GC-779-VJ',
  statut: 'string',
  sousStatut: 'string',
  dateMission: '2023-10-19T08:33:56.460Z',
  numeroSerie: 'string',
  type: 'string',
  carrosserie: 'string',
  segment: 'string',
  dateCreation: '2023-10-19T08:33:56.460Z',
  prixRetire: 9000,
  magasinSAP: 'string',
  societeDeVente: 'string',
  genre: 'string',
  marque: 'string',
  typeComm: 'string',
  dateLivraison: '2023-10-19T08:33:56.460Z',
  codeParcStockage: 1,
  fre: 100,
  proprietaireDebiteur: 'string',
  certificatImmatriculation: 'string',
  u_W3C_SFEI: 'string',
  cardCode: 'string',
  cardName: 'string',
  docEntryVente: 12,
  docEntryVenteE: 12,
  numeroOrdre: 'string',
  numeroOrdreComplement: 'string',
  date1MEC: '2023-10-19T08:33:56.460Z',
  tva: 'string',
  avarie: 'string',
  couleur: 'string',
  energie: 'string',
  puissance: 12,
  etat: 'string',
  places: 12,
  autoEcole: 'string',
  avaries: 'string',
  suiviEntretien: '2 : scan fait',
  carteCode: 'string',
  clefs: 'string',
  courroie: 'string',
  suiviCertifImmat: 'string',
  noteVisuelle: 'string',
  interieur: 'string',
  prixNeuf: 20304,
  expertise: 'string',
  age: 10,
  immatriculation2: '',
  roueSecoursAbsente: '',
  finGarantieContructeur: null,
  tarifSpecial: '',
  tarifSpecialElectronique: '',
  dateVenteAffecteeE: null,
  dateVenteAffectee: '2023-10-19T08:33:56.460Z',
  urlBE: '',
  urlSE: '',
  urlCT: '',
  urlCG: null,
  urlET: 'https://cdn.vpauto.fr/d/PJeeLrT_ET.jpg',
  url3D: '',
  nbJoursStock: 44,
  heure: 1212,
  heureE: 1944,
  equipementsPrint: '',
  modeleArgus: '',
  scanCG: '2 : scan fait',
  scanCT: '2 : scan fait',
  denonce: 'string',
  batterie: 'string',
  dateDerniereCotation: '',
  memoExterne:
    'Clim-jant.alu-regul.vites-BT-aidpark-vol.multif.-allum.auto.feux-detect.pluie-radio-ESP-ordibord-retro.elec-ant brouil',
}

const dummyVehicleSheetDTO2: VehicleSheetDTO = {
  metadata: {
    type: 'oiu',
    uri: 'kjh',
  },
  project: 'BBBBB',
  dossierRefVendeur: 'string',
  dateSortie: '2023-10-19T08:33:56.460Z',
  prixAdjudication: 10203,
  coteStandard: 205,
  cotePerso: 207,
  coteKm: 107,
  nbRetrait: 12,
  nbRetraitE: 12,
  estimation: 12003,
  reserve: 15678,
  kilomReel: 12,
  kilomDeclare: 12,
  observationsVendeur: 'string',
  immatriculation: 'STRING',
  statut: 'string',
  sousStatut: 'string',
  dateMission: '2023-10-19T08:33:56.460Z',
  numeroSerie: 'string',
  type: 'string',
  carrosserie: 'string',
  segment: 'string',
  dateCreation: '2023-10-19T08:33:56.460Z',
  prixRetire: 9000,
  magasinSAP: 'string',
  societeDeVente: 'string',
  genre: 'string',
  marque: 'string',
  typeComm: 'string',
  dateLivraison: '2023-10-19T08:33:56.460Z',
  codeParcStockage: 1664,
  fre: 100,
  proprietaireDebiteur: 'string',
  certificatImmatriculation: 'string',
  u_W3C_SFEI: 'string',
  cardCode: 'string',
  cardName: 'string',
  docEntryVente: 12,
  docEntryVenteE: 12,
  numeroOrdre: 'string',
  numeroOrdreComplement: 'string',
  date1MEC: '2023-10-19T08:33:56.460Z',
  tva: 'string',
  avarie: 'string',
  couleur: 'string',
  energie: 'string',
  puissance: 12,
  etat: '',
  places: 12,
  autoEcole: 'string',
  avaries: 'string',
  suiviEntretien: '2 : scan fait',
  carteCode: 'string',
  clefs: 'string',
  courroie: 'string',
  suiviCertifImmat: 'string',
  noteVisuelle: 'string',
  interieur: 'string',
  prixNeuf: 20304,
  expertise: 'string',
  age: 8,
  immatriculation2: '',
  roueSecoursAbsente: '',
  finGarantieContructeur: null,
  tarifSpecial: '',
  tarifSpecialElectronique: '',
  dateVenteAffecteeE: '2023-10-19T08:33:56.460Z',
  dateVenteAffectee: '2023-10-19T08:33:56.460Z',
  urlBE: '',
  urlSE: '',
  urlCT: '',
  urlET: 'https://cdn.vpauto.fr/d/PJeeLrT_ET.jpg',
  urlCG: '',
  url3D: '',
  nbJoursStock: 12,
  heure: 1420,
  heureE: 1415,
  equipementsPrint: '',
  modeleArgus: '',
  scanCG: '2 : scan fait',
  scanCT: '0 : pas de scan',
  denonce: '2',
  batterie: 'string',
  dateDerniereCotation: '',
  memoExterne: '',
}

const dummyVehicleSheetDTO3: VehicleSheetDTO = {
  metadata: {
    type: 'oiu',
    uri: 'kjh',
  },
  project: 'CCCCC',
  dossierRefVendeur: 'string',
  dateSortie: '2023-10-19T08:33:56.460Z',
  prixAdjudication: 10203,
  coteStandard: 205,
  cotePerso: 207,
  coteKm: 107,
  nbRetrait: 12,
  nbRetraitE: 12,
  estimation: 12003,
  reserve: 15678,
  kilomReel: 12,
  kilomDeclare: 12,
  observationsVendeur: 'string',
  immatriculation: 'string',
  statut: 'string',
  sousStatut: 'string',
  dateMission: '2023-10-19T08:33:56.460Z',
  numeroSerie: 'string',
  type: 'string',
  carrosserie: 'string',
  segment: 'string',
  dateCreation: '2023-10-19T08:33:56.460Z',
  prixRetire: 9000,
  magasinSAP: 'string',
  societeDeVente: 'string',
  genre: 'string',
  marque: 'string',
  typeComm: 'string',
  dateLivraison: '2023-10-19T08:33:56.460Z',
  codeParcStockage: null,
  fre: 100,
  proprietaireDebiteur: 'string',
  certificatImmatriculation: 'string',
  u_W3C_SFEI: 'string',
  cardCode: 'string',
  cardName: 'string',
  docEntryVente: 12,
  docEntryVenteE: 12,
  numeroOrdre: 'string',
  numeroOrdreComplement: 'string',
  date1MEC: '2023-10-19T08:33:56.460Z',
  tva: 'string',
  avarie: 'string',
  couleur: 'string',
  energie: 'string',
  puissance: 12,
  etat: 'string',
  places: 12,
  autoEcole: 'string',
  avaries: 'string',
  suiviEntretien: '1 : scan à faire',
  carteCode: 'string',
  clefs: 'string',
  courroie: 'string',
  suiviCertifImmat: 'string',
  noteVisuelle: 'string',
  interieur: 'string',
  prixNeuf: 20304,
  expertise: 'string',
  age: 8,
  immatriculation2: '',
  roueSecoursAbsente: '',
  finGarantieContructeur: null,
  tarifSpecial: '',
  tarifSpecialElectronique: '',
  dateVenteAffecteeE: '2023-10-19T08:33:56.460Z',
  dateVenteAffectee: '2023-10-19T08:33:56.460Z',
  urlBE: '',
  urlSE: '',
  urlCT: '',
  urlET: 'https://cdn.vpauto.fr/d/PJeeLrT_ET.jpg',
  urlCG: '',
  url3D: '',
  nbJoursStock: 15,
  heure: 1010,
  heureE: 1015,
  equipementsPrint: 'zkjfh vlkjjhvz kuzfj ikhzf opzefruhfp ioiuhvz vzepouihzp oiuh',
  modeleArgus: '',
  scanCG: '1 : scan à faire',
  scanCT: '',
  denonce: '2',
  batterie: 'string',
  dateDerniereCotation: '',
  memoExterne:
    'Clim-jant.alu-regul.vites-BT-aidpark-vol.multif.-allum.auto.feux-detect.pluie-radio-ESP-ordibord-retro.elec-ant brouil',
}

const dummyVehicleSheetDTO4: VehicleSheetDTO = {
  metadata: {
    type: 'vpasvc.ODataServices.extranet.VehiculeType',
    uri: "https://hanaservices.vpauto.fr:4300/vpasvc/ODataServices/extranet.xsodata/Vehicule('1495238')",
  },
  age: 1690,
  expertise: '5',
  prixNeuf: 28600,
  interieur: '7.5',
  noteVisuelle: '7.5',
  suiviCertifImmat: '0',
  courroie: '',
  finGarantieContructeur: null,
  roueSecoursAbsente: '',
  clefs: 'Y',
  carteCode: 'N',
  suiviEntretien: '0 : pas de scan',
  scanCG: '2 : scan fait',
  scanCT: '2 : scan fait',
  avaries: 'N',
  autoEcole: 'N',
  places: 5,
  etat: 'Origine import - Défaillance critique : pneumatiques - enlèvement conseillé par plateau ou par transporteur - prévoir entretien',
  puissance: 6,
  energie: 'ES',
  couleur: 'Gris fonce metal',
  avarie: '',
  tva: 'N',
  date1MEC: '2019-03-29T00:00:00Z',
  numeroOrdreComplement: '',
  numeroOrdre: '180',
  tarifSpecial: '',
  tarifSpecialElectronique: '307',
  docEntryVenteE: 0,
  docEntryVente: 21985,
  cardName: 'FINANCO',
  cardCode: 'FIN0002',
  u_W3C_SFEI: '1F17F843AAAF95C6C1256F6F004B2D7B',
  certificatImmatriculation: 'Certif immat',
  proprietaireDebiteur: 'OUANNOUN JONATHAN',
  fre: 560,
  immatriculation2: '',
  codeParcStockage: 2,
  dateLivraison: '2023-11-09T00:00:00Z',
  typeComm: '308 PureTech 130ch S&S BVM6 Tech Edition',
  marque: 'PEUGEOT',
  genre: 'VP',
  societeDeVente: 'SAS GUIGNARD et Associés - VPauto',
  magasinSAP: '1',
  dateVenteAffecteeE: null,
  prixRetire: 9879,
  dateCreation: '2023-08-30T00:00:00Z',
  segment: 'Compacte',
  carrosserie: 'CI',
  type: 'M10PGTVP0764336',
  numeroSerie: 'VF3LPHNSTJS440761',
  dateMission: '2023-10-06T00:00:00Z',
  sousStatut: null,
  statut: null,
  immatriculation: 'FK 397 DK',
  observationsVendeur:
    'DEFAILLANCE CRITIQUE-pneumatiques-enlèvement conseillé par plateau ou par transporteur-moyen+/import/ Badge Tech Edition/ Vitres ar. surteintees/ Rays/ Defaut alignement hayon-PC arD/Faire entretien-depasse de 10700 kil/ A noter client: Absence cric.',
  kilomDeclare: 0,
  kilomReel: 66352,
  reserve: 8600,
  estimation: 8600,
  nbRetraitE: 0,
  nbRetrait: 2,
  dateVenteAffectee: '2023-11-13T00:00:00Z',
  coteKm: 15800,
  cotePerso: 15700,
  coteStandard: 0,
  prixAdjudication: 0,
  dateSortie: null,
  dossierRefVendeur: '48515732',
  project: '1495238',
  urlBE: null,
  urlSE: null,
  urlCG: 'https://cdn.vpauto.fr/d/PJeeLrT_CG.pdf',
  urlCT: 'https://cdn.vpauto.fr/d/PJeeLrT_CT.pdf',
  urlET: 'https://cdn.vpauto.fr/d/PJeeLrT_ET.jpg',
  url3D: 'https://cdn.vpauto.fr/PJeeLrT/1080/3D_1/3d.html',
  nbJoursStock: 75,
  modeleArgus: '',
  heure: 1330,
  heureE: 0,
  equipementsPrint: '',
  batterie: '',
  denonce: '2',
  dateDerniereCotation: '',
  memoExterne:
    'Clim-jant.alu-regul.vites-BT-aidpark-vol.multif.-allum.auto.feux-detect.pluie-radio-ESP-ordibord-retro.elec-ant brouil',
}

const dummyVehicleSheetDTO5: VehicleSheetDTO = {
  metadata: {
    type: 'vpasvc.ODataServices.extranet.VehiculeType',
    uri: "https://hanaservices.vpauto.fr:4300/vpasvc/ODataServices/extranet.xsodata/Vehicule('1515101')",
  },
  age: 358,
  expertise: '6.5',
  prixNeuf: 32550,
  interieur: '7',
  noteVisuelle: '7.5',
  suiviCertifImmat: '0',
  courroie: '',
  finGarantieContructeur: null,
  roueSecoursAbsente: '',
  clefs: 'Y',
  carteCode: 'N',
  suiviEntretien: '2 : scan fait',
  scanCG: '2 : scan fait',
  scanCT: '0 : pas de scan',
  avaries: 'N',
  autoEcole: 'N',
  places: 5,
  etat: '',
  puissance: 7,
  energie: 'GO',
  couleur: 'Noir',
  avarie: '',
  tva: 'N',
  date1MEC: '2022-11-23T00:00:00Z',
  numeroOrdreComplement: '',
  numeroOrdre: '14',
  tarifSpecial: '',
  tarifSpecialElectronique: '307',
  docEntryVenteE: 0,
  docEntryVente: 22724,
  cardName: 'FINANCO',
  cardCode: 'FIN0002',
  u_W3C_SFEI: '1F17F843AAAF95C6C1256F6F004B2D7B',
  certificatImmatriculation: 'Certif immat',
  proprietaireDebiteur: 'COLOMBIER ALAIN LOUIS MARIE',
  fre: 1023,
  immatriculation2: '',
  codeParcStockage: 17,
  dateLivraison: '2023-11-03T00:00:00Z',
  typeComm: 'Renegade 1.6 I Multijet 130 ch BVM6 Night Eagle',
  marque: 'JEEP',
  genre: 'VP',
  societeDeVente: 'SAS GUIGNARD et Associés - VPauto',
  magasinSAP: '1',
  dateVenteAffecteeE: null,
  prixRetire: 0,
  dateCreation: '2023-10-23T00:00:00Z',
  segment: '4x4, SUV',
  carrosserie: 'BREAK',
  type: 'M10JEPVP002P722',
  numeroSerie: '1C4NJCES7NPN85947',
  dateMission: '2023-10-27T00:00:00Z',
  sousStatut: '',
  statut: '',
  immatriculation: 'GK 999 RL',
  observationsVendeur: 'moyen-',
  kilomDeclare: 0,
  kilomReel: 42508,
  reserve: 18500,
  estimation: 14900,
  nbRetraitE: 0,
  nbRetrait: 0,
  dateVenteAffectee: '2023-11-17T00:00:00Z',
  coteKm: 21900,
  cotePerso: 23000,
  coteStandard: 0,
  prixAdjudication: 0,
  dateSortie: null,
  dossierRefVendeur: '00953122',
  project: '1515101',
  urlBE:
    'http://bilanexpert.vpauto.fr/be/bilan_expert.php?vh=624F0658875669AAC1258A510051FC6C&ref=1515101&lang=fr',
  urlSE: null,
  urlCG: 'https://cdn.vpauto.fr/d/TLTRuXp_CG.pdf',
  urlCT: null,
  urlET: '',
  url3D: null,
  nbJoursStock: 24,
  modeleArgus: '',
  heure: 930,
  heureE: 0,
  equipementsPrint:
    'Clim-jant.alu-regul.vites-BT-aidpark-vol.multif.-allum.auto.feux-detect.pluie-radio-ESP-ordibord-retro.elec-ant brouil',
  batterie: '',
  denonce: '2',
  dateDerniereCotation: null,
  memoExterne:
    'Clim-jant.alu-regul.vites-BT-aidpark-vol.multif.-allum.auto.feux-detect.pluie-radio-ESP-ordibord-retro.elec-ant brouil',
}

const dummyVehicleSheetsDTOArray: VehicleSheetDTO[] = [
  dummyVehicleSheetDTO1,
  dummyVehicleSheetDTO2,
  dummyVehicleSheetDTO3,
  dummyVehicleSheetDTO4,
  dummyVehicleSheetDTO5,
]

export { dummyVehicleSheetDTO1, dummyVehicleSheetDTO2, dummyVehicleSheetsDTOArray }
